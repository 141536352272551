import React from 'react'

export const FullLogo = ({ color }) => (
  <svg width='511' height='34' viewBox='0 0 511 34' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M171.764 23.2032C170.641 19.4132 166.909 17.8366 161.063 17.4143V17.1521C168.892 16.7094 172.843 12.9773 172.843 8.15896C172.843 2.95579 169.971 0.449554 158.236 0.449554H146.857V1.03185C149.522 1.26681 149.896 1.61754 149.896 3.60619V30.2758C149.896 32.2645 149.522 32.639 146.857 32.8706V33.3167H159.855V32.8706C157.191 32.6356 156.793 32.2645 156.793 30.2758V17.6391H157.742C161.063 17.6391 164.04 18.8343 165.377 22.7639L166.085 24.8547C166.378 25.7196 166.589 26.6118 166.892 27.4733C168.065 30.8138 168.77 32.2032 169.518 33.3167H178.202V32.8263C176.994 32.4007 174.18 31.2463 171.764 23.2066V23.2032ZM156.793 17.0398V1.2634H160.056C165.878 1.64819 167.232 3.89223 167.232 8.61866C167.232 13.3213 162.754 17.0398 158.29 17.0398H156.796H156.793Z'
      fill={ color }/>
    <path
      d='M198.542 14.4109L193.503 12.6572C192.88 12.4392 192.308 12.2247 191.777 12.0102C191.604 11.9387 191.43 11.8672 191.267 11.7957C191.267 11.7957 191.26 11.7957 191.257 11.7923C188.028 10.3621 186.966 8.93869 186.966 6.52099C186.966 2.97616 189.076 1.10329 193.087 1.10329C198.011 1.10329 201.937 4.87967 204.442 11.5301C204.574 11.9114 204.704 12.2996 204.826 12.7014H205.343V0.446083H204.915C202.288 3.79 199.906 0 192.713 0C185.52 0 181.89 3.67423 181.89 9.61292C181.89 14.6663 184.625 17.1691 189.753 18.9943L194.751 20.7241C199.008 22.2122 201.965 23.5879 202.768 26.0942C202.832 26.387 202.87 26.6935 202.87 27.0204C202.87 30.7252 199.447 32.6832 195.119 32.6832C189.937 32.6832 187.136 28.9239 184.428 22.2769C184.163 21.5857 183.911 20.8672 183.676 20.1146H183.159V33.3098H183.611C186.367 29.8705 187.494 33.8717 195.054 33.8717C202.206 33.8717 207.756 30.1974 207.756 23.8808C207.756 18.6401 203.628 16.1611 198.545 14.4075L198.542 14.4109Z'
      fill={ color }/>
    <path
      d='M477.416 33.3132V32.8671C473.466 32.6321 472.976 32.2814 472.976 30.0612V1.40972H473.799C477.889 1.40972 479.573 2.92504 482.142 14.3632H482.727V0.446045H456.317V14.3632H456.902C459.379 2.99314 461.131 1.40972 465.249 1.40972H466.058V30.0578C466.058 32.2814 465.545 32.6287 461.618 32.8637V33.3098H477.419L477.416 33.3132Z'
      fill={ color }/>
    <path
      d='M337.682 33.3132L338.104 19.7059H337.494C335.484 30.8887 333.078 32.3359 327.746 32.3359H323.925V3.8138C323.925 1.56636 324.439 1.2633 328.389 1.02834V0.446045H313.99V1.02834C316.654 1.2633 317.028 1.61403 317.028 3.60268V30.2723C317.028 32.261 316.654 32.6355 313.99 32.8671V33.3132H337.678H337.682Z'
      fill={ color }/>
    <path
      d='M309.383 19.7059C307.372 30.8887 304.966 32.3359 299.634 32.3359H295.813V3.8138C295.813 1.56636 296.327 1.2633 300.277 1.02834V0.446045H285.878V1.02834C288.542 1.2633 288.916 1.61403 288.916 3.60268V30.2723C288.916 32.261 288.542 32.6355 285.878 32.8671V33.3132H309.566L309.988 19.7059H309.379H309.383Z'
      fill={ color }/>
    <path
      d='M452.168 33.3132V32.8671C449.503 32.6321 449.105 32.261 449.105 30.2723V3.60268C449.105 1.61403 449.503 1.2633 452.168 1.02834V0.446045H439.17V1.02834C441.834 1.2633 442.208 1.61403 442.208 3.60268V30.2723C442.208 32.261 441.834 32.6355 439.17 32.8671V33.3132H452.168Z'
      fill={ color }/>
    <path
      d='M510.997 21.1259H510.823H510.527C508.479 29.3563 505.253 32.3495 499.809 32.3495H496.652V17.2303H497.809C501.548 17.2303 502.834 18.5107 503.817 24.194H504.284V9.03399H503.817C502.838 14.6935 501.551 16.4778 497.809 16.4778H496.652V1.40972H499.622C504.995 1.40972 508.373 4.28713 510.405 11.7888H510.629H511L510.497 0.446045H486.72V1.02834C489.384 1.2633 489.758 1.61403 489.758 3.60268V30.2723C489.758 32.261 489.384 32.6355 486.72 32.8671V33.3132H510.497L511 21.1259H510.997Z'
      fill={ color }/>
    <path
      d='M234.891 30.2718C234.891 32.2604 234.516 32.635 231.852 32.8666V33.3127H244.85V32.8666C242.186 32.6316 241.811 32.2604 241.811 30.2718V3.60216C241.811 1.61351 242.186 1.26278 244.85 1.02782V0.445526H231.852V1.02782C234.516 1.26278 234.891 1.61351 234.891 3.60216V30.2718Z'
      fill={ color }/>
    <path
      d='M221.42 15.4864V3.60216C221.42 1.61351 221.818 1.26278 224.483 1.02782V0.445526H211.485V1.02782C214.149 1.26278 214.523 1.61351 214.523 3.60216V30.2718C214.523 32.2604 214.149 32.635 211.485 32.8666V33.3127H224.483V32.8666C221.818 32.6316 221.42 32.2604 221.42 30.2718V15.4864Z'
      fill={ color }/>
    <path d='M235.221 16.4733H221.142V17.2225H235.221V16.4733Z' fill={ color }/>
    <path
      d='M268.022 19.8927L271.374 30.013C272.146 32.1413 271.724 32.6316 268.076 32.8666V33.3127H281.54V32.8666C279.88 32.5874 279.461 32.2128 278.737 30.2718L268.076 0.445557H262.544L253.799 25.4262C252.047 30.1526 251.047 32.2809 249.41 32.8632V33.3093H258.573V32.8632C258.464 32.8496 258.366 32.8087 258.257 32.7917C256.743 32.4988 255.746 31.3955 255.184 30.4864C254.514 29.4069 254.534 27.7996 255.232 25.8587L256.12 23.4342L257.382 19.8893L257.981 18.2072L259.648 13.525L259.662 13.4909L262.639 5.1754C262.66 5.10729 262.683 5.0426 262.707 4.97449C262.707 4.97449 262.707 4.9779 262.707 4.9813L262.915 4.45349L267.467 18.2106L268.025 19.8927H268.022Z'
      fill={ color }/>
    <path d='M269.965 19.8218H256.113V20.571H269.965V19.8218Z' fill={ color }/>
    <path
      d='M129.089 19.8927L132.44 30.013C133.213 32.1413 132.791 32.6316 129.143 32.8666V33.3127H142.607V32.8666C140.947 32.5874 140.528 32.2128 139.804 30.2718L129.143 0.445557H123.611L114.866 25.4262C113.114 30.1526 112.114 32.2809 110.477 32.8632V33.3093H119.64V32.8632C119.531 32.8496 119.433 32.8087 119.324 32.7917C117.81 32.4988 116.813 31.3955 116.251 30.4864C115.581 29.4069 115.601 27.7996 116.299 25.8587L117.187 23.4342L118.449 19.8893L119.048 18.2072L120.715 13.525L120.729 13.4909L123.706 5.1754C123.727 5.10729 123.75 5.0426 123.774 4.97449C123.774 4.97449 123.774 4.9779 123.774 4.9813L123.982 4.45349L128.534 18.2106L129.092 19.8927H129.089Z'
      fill={ color }/>
    <path d='M131.035 19.8218H117.183V20.571H131.035V19.8218Z' fill={ color }/>
    <path
      d='M411.649 15.4864V3.60219C411.649 1.61355 412.047 1.26281 414.711 1.02785V0.445557H401.713V1.02785C404.378 1.26281 404.752 1.61355 404.752 3.60219V30.2718C404.752 32.2605 404.378 32.635 401.713 32.8666V33.3127H414.711V32.8666C412.047 32.6316 411.649 32.2605 411.649 30.2718V15.4864Z'
      fill={ color }/>
    <path
      d='M425.12 30.2718C425.12 32.2604 424.745 32.635 422.081 32.8665V33.3126H435.079V32.8665C432.415 32.6316 432.04 32.2604 432.04 30.2718V3.60214C432.04 1.61349 432.415 1.26275 435.079 1.02779V0.445503H422.081V1.02779C424.745 1.26275 425.12 1.61349 425.12 3.60214V30.2718Z'
      fill={ color }/>
    <path d='M425.447 16.4734H411.367V17.2225H425.447V16.4734Z' fill={ color }/>
    <path
      d='M387.937 0.446154V1.02845C392.285 1.59031 393.102 5.05341 390.601 13.5222L385.745 30.8139L379.124 3.77645L379.083 3.59938C378.573 1.50518 379.607 1.26 382.271 1.02504V0.442749H369.121V1.02504C371.645 1.24638 372.826 1.3247 372.431 3.26567L368.117 21.0068C367.552 23.2951 367.127 25.0727 366.657 27.1669L365.854 30.8104L359.423 6.69132C357.974 1.68565 358.324 1.26341 361.972 1.03185V0.449559H348.647V1.03185C350.308 1.31108 350.822 1.70949 351.383 3.60619L360.08 33.3167H365.854L370.342 15.4189C371.043 12.5891 371.676 9.9433 372.445 6.27248H372.935C373.707 9.9467 374.313 12.5176 375.085 15.3712L379.852 33.3133H385.82L392.333 10.5971C393.898 4.77078 395.371 1.96488 397.171 1.02845V0.446154H387.937Z'
      fill={ color }/>
    <path
      d='M86.9077 27.2076H86.4177C85.7406 25.0317 85.0839 23.2065 84.0087 20.4244L76.2509 0.446045H66.5503V1.02834C69.0988 1.2633 69.5888 1.80132 69.5888 4.35183V24.3064C69.5888 28.7059 68.9117 31.1372 66.5503 32.8433V33.3098H75.3629V32.8433C71.5997 31.4165 70.2897 28.774 70.2897 23.3699V11.4857C70.2897 11.3904 70.2897 11.2951 70.2897 11.1997V4.58679H70.6163L81.1948 31.7808H85.8665L96.4212 4.58679H96.5607V11.7718C96.5607 11.8092 96.5607 11.8467 96.5607 11.8876V30.2757C96.5607 32.2405 96.1864 32.6151 93.5222 32.8501V33.3166H106.52V32.8501C103.856 32.6151 103.481 32.2405 103.481 30.2757V4.35183C103.481 1.80132 103.971 1.2633 106.52 1.02834V0.446045H97.1697L86.9077 27.2042V27.2076Z'
      fill={ color }/>
    <path
      d='M39.4967 10.9409H33.7804V0.446045H18.292V10.9409H12.5758V6.83423H0V33.3098H21.5415V27.2178C21.5415 24.7388 23.5592 22.7196 26.0362 22.7196C28.5133 22.7196 30.531 24.7354 30.531 27.2178V33.3098H52.0724V6.83763H39.4967V10.9443V10.9409Z'
      fill={ color }/>
  </svg>
)

export const Logo = ({ color = '#0D1536' }) => (
  <svg width='315' height='24' viewBox='0 0 315 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M75.2452 16.3787C74.4526 13.7034 71.8178 12.5905 67.6915 12.2925V12.1074C73.2181 11.7949 76.0066 9.16046 76.0066 5.75925C76.0066 2.08643 73.9794 0.317322 65.6957 0.317322H57.6641V0.728351C59.5447 0.894205 59.8089 1.14178 59.8089 2.54553V21.3712C59.8089 22.7749 59.5447 23.0393 57.6641 23.2028V23.5176H66.8389V23.2028C64.9583 23.0369 64.6773 22.7749 64.6773 21.3712V12.4511H65.3474C67.6915 12.4511 69.7931 13.2948 70.737 16.0686L71.2366 17.5445C71.4431 18.155 71.5921 18.7848 71.8058 19.3929C72.6344 21.7509 73.1316 22.7316 73.66 23.5176H79.7894V23.1715C78.9367 22.8711 76.9505 22.0562 75.2452 16.3811V16.3787ZM64.6773 12.0281V0.891801H66.9806C71.0901 1.16342 72.046 2.74744 72.046 6.08375C72.046 9.40323 68.8852 12.0281 65.7341 12.0281H64.6797H64.6773Z'
      fill={color}/>
    <path
      d='M94.147 10.1724L90.5899 8.93448C90.1504 8.78064 89.7469 8.62921 89.3722 8.47778C89.2497 8.4273 89.1272 8.37683 89.0119 8.32635C89.0119 8.32635 89.0071 8.32635 89.0047 8.32395C86.7254 7.3144 85.976 6.30966 85.976 4.60305C85.976 2.10082 87.4652 0.778793 90.2969 0.778793C93.7723 0.778793 96.5439 3.44447 98.3117 8.13886C98.4053 8.40807 98.4966 8.68209 98.5831 8.96573H98.9481V0.314882H98.6455C96.7913 2.6753 95.1101 0 90.0327 0C84.9553 0 82.3926 2.59357 82.3926 6.78559C82.3926 10.3527 84.3236 12.1194 87.9431 13.4077L91.4714 14.6288C94.476 15.6792 96.5631 16.6503 97.13 18.4194C97.1756 18.6261 97.202 18.8425 97.202 19.0732C97.202 21.6884 94.7858 23.0705 91.7307 23.0705C88.0728 23.0705 86.0961 20.4169 84.1843 15.7249C83.997 15.2369 83.8192 14.7298 83.6535 14.1985H83.2884V23.5128H83.6079C85.5533 21.0851 86.3483 23.9094 91.6851 23.9094C96.7337 23.9094 100.651 21.3158 100.651 16.857C100.651 13.1577 97.7376 11.4079 94.1494 10.17L94.147 10.1724Z'
      fill={color}/>
    <path
      d='M290.999 23.5152V23.2003C288.211 23.0344 287.865 22.7869 287.865 21.2197V0.995091H288.446C291.333 0.995091 292.522 2.06473 294.336 10.1387H294.749V0.31485H276.106V10.1387H276.519C278.268 2.1128 279.504 0.995091 282.411 0.995091H282.982V21.2173C282.982 22.7869 282.62 23.032 279.848 23.1979V23.5128H291.002L290.999 23.5152Z'
      fill={color}/>
    <path
      d='M192.364 23.5152L192.662 13.9101H192.232C190.812 21.8038 189.114 22.8253 185.351 22.8253H182.653V2.69209C182.653 1.10566 183.016 0.891733 185.805 0.725879V0.31485H175.64V0.725879C177.521 0.891733 177.785 1.13931 177.785 2.54306V21.3687C177.785 22.7724 177.521 23.0368 175.64 23.2003V23.5152H192.361H192.364Z'
      fill={color}/>
    <path
      d='M172.388 13.9101C170.969 21.8038 169.27 22.8253 165.507 22.8253H162.81V2.69209C162.81 1.10566 163.172 0.891733 165.961 0.725879V0.31485H155.796V0.725879C157.677 0.891733 157.941 1.13931 157.941 2.54306V21.3687C157.941 22.7724 157.677 23.0368 155.796 23.2003V23.5152H172.518L172.815 13.9101H172.386H172.388Z'
      fill={color}/>
    <path
      d='M273.177 23.5152V23.2003C271.297 23.0344 271.016 22.7724 271.016 21.3687V2.54306C271.016 1.13931 271.297 0.891733 273.177 0.725879V0.31485H264.002V0.725879C265.883 0.891733 266.147 1.13931 266.147 2.54306V21.3687C266.147 22.7724 265.883 23.0368 264.002 23.2003V23.5152H273.177Z'
      fill={color}/>
    <path
      d='M314.704 14.9124H314.581H314.372C312.926 20.7221 310.649 22.8349 306.807 22.8349H304.578V12.1626H305.394C308.034 12.1626 308.942 13.0664 309.636 17.0781H309.965V6.37693H309.636C308.944 10.3719 308.036 11.6314 305.394 11.6314H304.578V0.995091H306.674C310.467 0.995091 312.852 3.0262 314.286 8.32151H314.444H314.706L314.351 0.31485H297.567V0.725879C299.447 0.891733 299.712 1.13931 299.712 2.54306V21.3687C299.712 22.7724 299.447 23.0368 297.567 23.2003V23.5152H314.351L314.706 14.9124H314.704Z'
      fill={color}/>
    <path
      d='M119.805 21.3683C119.805 22.7721 119.541 23.0365 117.661 23.1999V23.5148H126.835V23.1999C124.955 23.0341 124.691 22.7721 124.691 21.3683V2.5427C124.691 1.13895 124.955 0.891367 126.835 0.725513V0.314484H117.661V0.725513C119.541 0.891367 119.805 1.13895 119.805 2.5427V21.3683Z'
      fill={color}/>
    <path
      d='M110.297 10.9315V2.5427C110.297 1.13895 110.578 0.891367 112.459 0.725513V0.314484H103.284V0.725513C105.164 0.891367 105.428 1.13895 105.428 2.5427V21.3683C105.428 22.7721 105.164 23.0365 103.284 23.1999V23.5148H112.459V23.1999C110.578 23.0341 110.297 22.7721 110.297 21.3683V10.9315Z'
      fill={color}/>
    <path d='M120.039 11.6282H110.1V12.157H120.039V11.6282Z' fill={color}/>
    <path
      d='M143.192 14.0419L145.558 21.1857C146.103 22.688 145.805 23.0341 143.23 23.2V23.5148H152.734V23.2C151.562 23.0029 151.267 22.7385 150.755 21.3684L143.23 0.314514H139.325L133.153 17.9479C131.916 21.2842 131.209 22.7865 130.054 23.1976V23.5124H136.522V23.1976C136.445 23.1879 136.376 23.1591 136.299 23.1471C135.23 22.9404 134.526 22.1616 134.13 21.5198C133.657 20.7578 133.671 19.6233 134.164 18.2532L134.791 16.5418L135.682 14.0395L136.104 12.8521L137.281 9.54705L137.291 9.52301L139.392 3.65323C139.407 3.60515 139.424 3.55948 139.44 3.51141C139.44 3.51141 139.44 3.51381 139.44 3.51622L139.587 3.14365L142.801 12.8545L143.194 14.0419H143.192Z'
      fill={color}/>
    <path d='M144.563 13.9919H134.786V14.5207H144.563V13.9919Z' fill={color}/>
    <path
      d='M45.1217 14.0419L47.4874 21.1857C48.0327 22.688 47.7348 23.0341 45.1601 23.2V23.5148H54.664V23.2C53.4919 23.0029 53.1965 22.7385 52.6849 21.3684L45.1601 0.314514H41.2548L35.0822 17.9479C33.8453 21.2842 33.1391 22.7865 31.9839 23.1976V23.5124H38.4519V23.1976C38.3751 23.1879 38.3054 23.1591 38.2285 23.1471C37.1597 22.9404 36.456 22.1616 36.0597 21.5198C35.5866 20.7578 35.601 19.6233 36.0933 18.2532L36.7202 16.5418L37.6113 14.0395L38.034 12.8521L39.2109 9.54705L39.2205 9.52301L41.3221 3.65323C41.3365 3.60515 41.3533 3.55948 41.3701 3.51141C41.3701 3.51141 41.3701 3.51381 41.3701 3.51622L41.5166 3.14365L44.7302 12.8545L45.1241 14.0419H45.1217Z'
      fill={color}/>
    <path d='M46.4955 13.9919H36.7178V14.5207H46.4955V13.9919Z' fill={color}/>
    <path
      d='M244.576 10.9316V2.54273C244.576 1.13898 244.857 0.891397 246.737 0.725544V0.314514H237.562V0.725544C239.443 0.891397 239.707 1.13898 239.707 2.54273V21.3684C239.707 22.7721 239.443 23.0365 237.562 23.2V23.5148H246.737V23.2C244.857 23.0341 244.576 22.7721 244.576 21.3684V10.9316Z'
      fill={color}/>
    <path
      d='M254.084 21.3683C254.084 22.7721 253.82 23.0365 251.939 23.1999V23.5148H261.114V23.1999C259.234 23.0341 258.97 22.7721 258.97 21.3683V2.5427C258.97 1.13895 259.234 0.891367 261.114 0.725513V0.314484H251.939V0.725513C253.82 0.891367 254.084 1.13895 254.084 2.5427V21.3683Z'
      fill={color}/>
    <path d='M254.316 11.6283H244.377V12.1571H254.316V11.6283Z' fill={color}/>
    <path
      d='M227.837 0.314934V0.725964C230.907 1.12257 231.483 3.56711 229.718 9.54507L226.291 21.751L221.617 2.66573L221.588 2.54074C221.228 1.06248 221.958 0.889414 223.838 0.72356V0.312531H214.556V0.72356C216.338 0.879799 217.171 0.935084 216.892 2.30518L213.847 14.8284C213.448 16.4436 213.148 17.6984 212.817 19.1766L212.25 21.7486L207.71 4.72328C206.687 1.18987 206.935 0.891818 209.509 0.728367V0.317338H200.104V0.728367C201.276 0.925469 201.639 1.2067 202.035 2.54555L208.174 23.5177H212.25L215.418 10.8839C215.913 8.88646 216.359 7.0188 216.902 4.42763H217.248C217.793 7.0212 218.221 8.83598 218.766 10.8503L222.131 23.5153H226.344L230.941 7.48031C232.045 3.36761 233.085 1.38698 234.356 0.725964V0.314934H227.837Z'
      fill={color}/>
    <path
      d='M15.3465 19.2054H15.0006C14.5227 17.6694 14.0591 16.3811 13.3002 14.4172L7.82407 0.31485H0.976562V0.725879C2.7755 0.891733 3.12136 1.27151 3.12136 3.07187V17.1574C3.12136 20.263 2.64341 21.9792 0.976562 23.1835V23.5128H7.1972V23.1835C4.54082 22.1763 3.61613 20.3111 3.61613 16.4964V8.10758C3.61613 8.04028 3.61613 7.97298 3.61613 7.90567V3.23773H3.8467L11.3139 22.4335H14.6115L22.0619 3.23773H22.1604V8.30949C22.1604 8.33593 22.1604 8.36237 22.1604 8.39122V21.3711C22.1604 22.758 21.8962 23.0224 20.0156 23.1883V23.5176H29.1904V23.1883C27.3098 23.0224 27.0456 22.758 27.0456 21.3711V3.07187C27.0456 1.27151 27.3915 0.891733 29.1904 0.725879V0.31485H22.5903L15.3465 19.203V19.2054Z'
      fill={color}/>
  </svg>
)

export const Castle = ({ color = '#0D1536' }) => (
  <svg width='37' height='24' viewBox='0 0 37 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M27.88 7.40814H23.845V0H12.912V7.40814H8.87702V4.5093H0V23.1979H15.2057V18.8977C15.2057 17.1479 16.63 15.7225 18.3785 15.7225C20.127 15.7225 21.5513 17.1455 21.5513 18.8977V23.1979H36.757V4.51171H27.88V7.41055V7.40814Z'
      fill={ color }/>
  </svg>
)

export const TwitterX = ({ color }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.3333 10.9361L18.5459 5.00244H17.3111L12.7831 10.1535L9.1692 5.00244H5L10.4661 12.7925L5 19.0141H6.2348L11.0135 13.5732L14.8308 19.0141H19M6.68047 5.91431H8.57747L17.3102 18.147H15.4127" fill={color}/>
  </svg>
)

export const WhatsApp = ({ color }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.32516 19.8833C8.70016 20.7083 10.3502 21.1667 12.0002 21.1667C17.0418 21.1667 21.1668 17.0417 21.1668 12C21.1668 6.95834 17.0418 2.83334 12.0002 2.83334C6.9585 2.83334 2.8335 6.95834 2.8335 12C2.8335 13.65 3.29183 15.2083 4.02516 16.5833L2.8335 21.1667L7.32516 19.8833Z" stroke="#0D1536" strokeWidth="0.7" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M16.125 14.6111C16.125 14.7596 16.092 14.9122 16.0217 15.0607C15.9515 15.2092 15.8606 15.3495 15.7408 15.4815C15.5384 15.7043 15.3153 15.8651 15.0633 15.9683C14.8154 16.0714 14.5469 16.125 14.2577 16.125C13.8363 16.125 13.386 16.026 12.9109 15.8239C12.4358 15.6218 11.9608 15.3495 11.4898 15.0071C11.0147 14.6606 10.5644 14.277 10.1348 13.8521C9.70925 13.4231 9.32505 12.9735 8.98216 12.5033C8.6434 12.033 8.37074 11.5628 8.17245 11.0966C7.97415 10.6264 7.875 10.1767 7.875 9.74775C7.875 9.46725 7.92457 9.19913 8.02372 8.95163C8.12287 8.7 8.27986 8.469 8.49881 8.26275C8.76321 8.00288 9.05239 7.875 9.3581 7.875C9.47377 7.875 9.58945 7.89975 9.69273 7.94925C9.80014 7.99875 9.89516 8.073 9.96952 8.18025L10.928 9.52913C11.0023 9.63225 11.056 9.72712 11.0932 9.81787C11.1304 9.9045 11.151 9.99113 11.151 10.0695C11.151 10.1685 11.1221 10.2675 11.0643 10.3624C11.0106 10.4572 10.9321 10.5562 10.8329 10.6552L10.519 10.9811C10.4735 11.0265 10.4529 11.0801 10.4529 11.1461C10.4529 11.1791 10.457 11.208 10.4653 11.241C10.4777 11.274 10.49 11.2987 10.4983 11.3235C10.5727 11.4596 10.7007 11.637 10.8825 11.8515C11.0684 12.066 11.2667 12.2846 11.4815 12.5033C11.7046 12.7219 11.9194 12.924 12.1384 13.1096C12.3532 13.2911 12.5309 13.4149 12.6713 13.4891C12.692 13.4974 12.7168 13.5097 12.7457 13.5221C12.7787 13.5345 12.8118 13.5386 12.849 13.5386C12.9192 13.5386 12.9729 13.5139 13.0183 13.4685L13.3323 13.1591C13.4356 13.056 13.5347 12.9776 13.6298 12.9281C13.7248 12.8704 13.8198 12.8415 13.9231 12.8415C14.0016 12.8415 14.0842 12.858 14.1751 12.8951C14.266 12.9322 14.361 12.9859 14.4643 13.056L15.8317 14.0254C15.9391 14.0996 16.0135 14.1862 16.0589 14.2894C16.1002 14.3925 16.125 14.4956 16.125 14.6111Z" stroke="#0D1536" strokeWidth="0.7" stroke-miterlimit="10"/>
  </svg>
)

export const Sms = ({ color }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4 5V21L8 17H20V5H4Z" stroke="#0D1536" strokeWidth="0.7" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M12 11V11.01" stroke="#0D1536" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M8 11V11.01" stroke="#0D1536" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M16 11V11.01" stroke="#0D1536" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
)

export const Facebook = ({ color }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M12.9092 18.7H9.9294V11.6991H8.44043V9.28684H9.9294V7.8386C9.9294 5.87079 10.7697 4.7 13.1586 4.7H15.147V7.11315H13.9042C12.9743 7.11315 12.9128 7.45025 12.9128 8.07938L12.9087 9.28684H15.1606L14.897 11.6991H12.9087L12.9092 18.7Z" fill={color}/>
  </svg>
)

export const CopyLink = ({ color }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.37495 14.6251L14.6255 9.37467M11.1251 6.7495L11.5303 6.28046C12.3509 5.45993 13.464 4.999 14.6245 4.99908C15.785 4.99917 16.8979 5.46025 17.7185 6.2809C18.539 7.10155 18.9999 8.21455 18.9999 9.37504C18.9998 10.5355 18.5387 11.6485 17.718 12.469L17.2507 12.875M12.8754 17.2504L12.528 17.7176C11.6978 18.5386 10.5773 18.9991 9.40964 18.9991C8.24202 18.9991 7.1215 18.5386 6.29126 17.7176C5.88204 17.313 5.55715 16.8312 5.33542 16.3001C5.11368 15.7691 4.99951 15.1993 4.99951 14.6238C4.99951 14.0483 5.11368 13.4785 5.33542 12.9475C5.55715 12.4164 5.88204 11.9346 6.29126 11.53L6.74981 11.1248" stroke={color} strokeWidth="0.875081" strokeLinecap="square" strokeLinejoin="round"/>
  </svg>
)

export const CastleInverted = ({ color }) => (
  <svg width="1440" height="800" viewBox="0 0 1440 800" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M1440 0H0V800H1440V0ZM851.765 294.875H949.026V225.33H1163V674H796.477V570.749C796.477 528.676 762.146 494.509 720 494.509C677.854 494.509 643.523 528.733 643.523 570.749V674H277V225.272H490.974V294.875H588.235V117H851.765V294.875Z" fill="white"/>
  </svg>
)

export const CastleInvertedMobile = ({ color }) => (
  <svg width="390" height="844" viewBox="0 0 390 844" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M390 0H0V844H390V0ZM242.59 362.387H277.718V337.269H355V499.317H222.622V462.026C222.622 446.83 210.222 434.49 195 434.49C179.778 434.49 167.378 446.851 167.378 462.026V499.317H35V337.248H112.282V362.387H147.41V298.143H242.59V362.387Z" fill="white"/>
  </svg>
)

export const LinkedIn = ({ color }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M6.03125 9.74538H8.48153V18.2849H6.03125V9.74538Z" fill={color}/>
    <path fillRule="evenodd" clipRule="evenodd" d="M7.19413 8.67723H7.17639C6.28941 8.67723 5.71436 8.02443 5.71436 7.19777C5.71436 6.3542 6.30641 5.71428 7.21113 5.71428C8.11511 5.71428 8.67095 6.35259 8.68869 7.19535C8.68869 8.02202 8.11511 8.67723 7.19413 8.67723Z" fill={color}/>
    <path fillRule="evenodd" clipRule="evenodd" d="M18.2856 18.2857H15.5071V13.8658C15.5071 12.7091 15.0725 11.9203 14.1168 11.9203C13.3857 11.9203 12.9792 12.4524 12.79 12.9667C12.719 13.1502 12.7301 13.407 12.7301 13.6646V18.2857H9.97754C9.97754 18.2857 10.013 10.4569 9.97754 9.74537H12.7301V11.0856C12.8927 10.4996 13.7723 9.66327 15.176 9.66327C16.9174 9.66327 18.2856 10.8924 18.2856 13.5366V18.2857Z" fill={color}/>
  </svg>
)

export const YouTube = ({ color }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M19.4284 14.7048V9.40947C19.4284 9.40947 19.4284 6.85715 16.8681 6.85715H7.13076C7.13076 6.85715 4.57129 6.85715 4.57129 9.40947V14.7048C4.57129 14.7048 4.57129 17.2571 7.13076 17.2571H16.8681C16.8681 17.2571 19.4284 17.2571 19.4284 14.7048ZM14.8843 12.0646L10.0204 14.9121V9.21615L14.8843 12.0646Z" fill={color}/>
  </svg>
)

export const Instagram = ({ color }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.0035 15.2312C13.7155 15.2312 15.1033 13.8434 15.1033 12.1315C15.1033 10.4195 13.7155 9.03172 12.0035 9.03172C10.2916 9.03172 8.90381 10.4195 8.90381 12.1315C8.90381 13.8434 10.2916 15.2312 12.0035 15.2312Z" fill={color}/>
    <path d="M17.3601 5.01274H6.64702C5.75046 5.01274 5.02148 5.74256 5.02148 6.63828V17.3522C5.02148 18.2488 5.75046 18.9777 6.64702 18.9777H17.3601V18.9779C18.2567 18.9779 18.9856 18.2489 18.9856 17.3523V6.63828C18.9856 5.7427 18.2567 5.01274 17.3601 5.01274ZM12.0036 15.9423C9.90272 15.9423 8.19346 14.2323 8.19346 12.1315C8.19346 10.0306 9.90272 8.32136 12.0036 8.32136C14.1044 8.32136 15.8137 10.0306 15.8137 12.1315C15.8137 14.2323 14.1044 15.9423 12.0036 15.9423ZM16.6801 8.36238C16.1151 8.36238 15.657 7.90346 15.657 7.33926C15.657 6.77506 16.1151 6.31698 16.6801 6.31698C17.2452 6.31698 17.7032 6.77422 17.7032 7.33926C17.7032 7.9043 17.2453 8.36238 16.6801 8.36238Z" fill={color}/>
  </svg>
)

export const Pinterest = ({ color }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.219 3.75C7.90199 3.75 5.60999 6.6495 5.60999 9.80775C5.60999 11.274 6.38924 13.1018 7.64024 13.6838C7.82999 13.7723 7.93049 13.7318 7.97249 13.5488C8.00774 13.41 8.17724 12.732 8.25224 12.4155C8.26647 12.3652 8.26739 12.3121 8.2549 12.2613C8.24241 12.2106 8.21694 12.1639 8.18099 12.126C7.76849 11.6257 7.43999 10.704 7.43999 9.84525C7.43999 7.63875 9.11099 5.505 11.9557 5.505C14.4157 5.505 16.134 7.1775 16.134 9.5745C16.134 12.282 14.769 14.1555 12.9907 14.1555C12.009 14.1555 11.2725 13.3448 11.5072 12.3458C11.7907 11.1578 12.3367 9.87225 12.3367 9.01725C12.3367 8.24775 11.9227 7.605 11.073 7.605C10.0672 7.605 9.26174 8.64375 9.26174 10.0357C9.26174 10.9222 9.56174 11.5193 9.56174 11.5193C9.56174 11.5193 8.56949 15.714 8.38949 16.4948C8.07974 17.8185 8.42774 19.9635 8.45999 20.1517C8.47574 20.2522 8.58899 20.2867 8.64899 20.2012C8.74574 20.07 9.94274 18.2775 10.2795 16.9822L10.9005 14.604C11.2297 15.2258 12.1807 15.7523 13.1962 15.7523C16.212 15.7523 18.3885 12.975 18.3885 9.528C18.39 6.22575 15.69 3.75 12.219 3.75Z" fill={color}/>
  </svg>
)

export const RightArrow = ({ color }) => (
  <svg width='15' height='10' viewBox='0 0 15 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M0 5H14M14 5L10 9M14 5L10 1' stroke={ color } strokeLinejoin='round'/>
  </svg>
)

export const Info = ({ color }) => (
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5 11.9999C5 12.9848 5.19399 13.9601 5.5709 14.87C5.94781 15.7799 6.50026 16.6067 7.1967 17.3032C7.89314 17.9996 8.71993 18.5521 9.62987 18.929C10.5398 19.3059 11.5151 19.4999 12.5 19.4999C13.4849 19.4999 14.4602 19.3059 15.3701 18.929C16.2801 18.5521 17.1069 17.9996 17.8033 17.3032C18.4997 16.6067 19.0522 15.7799 19.4291 14.87C19.806 13.9601 20 12.9848 20 11.9999C20 10.0108 19.2098 8.1031 17.8033 6.69658C16.3968 5.29005 14.4891 4.49988 12.5 4.49988C10.5109 4.49988 8.60322 5.29005 7.1967 6.69658C5.79018 8.1031 5 10.0108 5 11.9999Z" stroke={color} strokeWidth="0.833333" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M13.1317 8.83578H11.9705V7.58813H13.1317V8.83578ZM13.0699 16.3464H12.0446V10.1328H13.0699V16.3464Z" fill={color}/>
  </svg>
)

export const Upload = ({ color }) => (
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.9167 17.0278L11.9167 5.5M11.9167 5.5L16.1313 9.71457M11.9167 5.5L7.70215 9.71457" stroke={color} strokeWidth="0.7" strokeMiterlimit="10"/>
    <path d="M19.0639 17.77V20.4998H5V17.77" stroke={color} strokeWidth="0.7" strokeMiterlimit="10"/>
  </svg>
)

export const Loading = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="50px" height="50px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
    <circle cx="50" cy="50" fill="none" stroke='#000' strokeWidth="10" r="25" strokeDasharray="117.80972450961724 41.269908169872416"></circle>
  </svg>
)

export const Search = ({ color }) => (
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M14.75 14.75L10.25 10.25M1.25 6.5C1.25 7.18944 1.3858 7.87213 1.64963 8.50909C1.91347 9.14605 2.30018 9.7248 2.78769 10.2123C3.2752 10.6998 3.85395 11.0865 4.49091 11.3504C5.12787 11.6142 5.81056 11.75 6.5 11.75C7.18944 11.75 7.87213 11.6142 8.50909 11.3504C9.14605 11.0865 9.7248 10.6998 10.2123 10.2123C10.6998 9.7248 11.0865 9.14605 11.3504 8.50909C11.6142 7.87213 11.75 7.18944 11.75 6.5C11.75 5.81056 11.6142 5.12787 11.3504 4.49091C11.0865 3.85395 10.6998 3.2752 10.2123 2.78769C9.7248 2.30018 9.14605 1.91347 8.50909 1.64963C7.87213 1.3858 7.18944 1.25 6.5 1.25C5.81056 1.25 5.12787 1.3858 4.49091 1.64963C3.85395 1.91347 3.2752 2.30018 2.78769 2.78769C2.30018 3.2752 1.91347 3.85395 1.64963 4.49091C1.3858 5.12787 1.25 5.81056 1.25 6.5Z'
      stroke={color} strokeWidth='1.125' strokeLinejoin='round'/>
  </svg>
)

export const CirclePlay = ({ color }) => (
  <svg width='48' height='48' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <circle cx='24' cy='24' r='24' fill='white'/>
    <path
      d='M18.4185 34.3368C18.4888 34.3743 18.5666 34.393 18.6435 34.393C18.7372 34.393 18.8312 34.3661 18.9118 34.3117L33.6267 24.3985C33.7589 24.3085 33.8386 24.1595 33.8386 24.0001C33.8386 23.8398 33.7589 23.6907 33.6267 23.6016L18.9125 13.6884C18.7653 13.59 18.575 13.5797 18.4185 13.6631C18.261 13.7466 18.1635 13.9097 18.1635 14.0869V33.913C18.1635 34.0902 18.2619 34.2533 18.4185 34.3368Z'
      fill='#0D1536'/>
  </svg>
)

export const ButtonRightArrow = ({ color = '#0D1536' }) => (
  <svg width='10' height='6' viewBox='0 0 10 6' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M0.333496 2.99992H9.66683M9.66683 2.99992L7.00016 5.66659M9.66683 2.99992L7.00016 0.333252' stroke={ color } strokeWidth='0.666667' strokeLinejoin='round'/>
  </svg>
)

export const Plus = ({ color = '#0D1536' }) => (
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M8.00016 3.33337V12.6667M3.3335 8.00004H12.6668' stroke={color} strokeWidth='0.666667' strokeLinejoin='round'/>
  </svg>
)

export const Close = ({ color = '#0D1536' }) => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g id='icons/24/close'>
      <path id='Vector' d='M18 6L6 18M6 6L18 18' stroke={ color } strokeLinejoin='round'/>
    </g>
  </svg>
)

export const Accordion = ({ color = '#0D1536' }) => (
  <svg width='25' height='24' viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M12.5 5V19M5.5 12H19.5' stroke={ color } strokeLinejoin='round'/>
  </svg>
)

export const ChevronDown = ({ color = '#0D1536' }) => (
  <svg width='16' height='17' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M4 6.5L8 10.5L12 6.5' stroke={ color } strokeWidth='0.666667' strokeLinejoin='round'/>
  </svg>
)

export const StickyLeftArrow = ({ color = '#0D1536' }) => (
  <svg width='5' height='10' viewBox='0 0 5 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M4.74976 1L0.749756 5L4.74976 9' stroke={ color } strokeWidth='0.666667' strokeLinejoin='round'/>
  </svg>
)

export const StickyRightArrow = ({ color = '#0D1536' }) => (
  <svg width='5' height='10' viewBox='0 0 5 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M0.25 1L4.25 5L0.25 9' stroke={ color } strokeWidth='0.666667' strokeLinejoin='round'/>
  </svg>
)

export const Bed = ({ color = '#0D1536' }) => (
  <svg width='25' height='24' viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M3.54004 17.3423V19.4639' stroke={ color } strokeWidth='0.7' strokeMiterlimit='10'/>
    <path d='M21.7402 17.3423V19.4639' stroke={ color } strokeWidth='0.7' strokeMiterlimit='10'/>
    <path d='M3.696 15.3948H21.8986C22.3848 15.3948 22.78 15.7432 22.78 16.1748V17.3422H2.5V16.1748C2.5 15.7458 2.8952 15.3948 3.3814 15.3948H18.0194' stroke={ color } strokeWidth='0.7'
          strokeMiterlimit='10'/>
    <path d='M4.99609 11.6144V5.41699H12.6401' stroke={ color } strokeWidth='0.7' strokeMiterlimit='10'/>
    <path d='M20.2841 11.6144V5.41699H12.6401' stroke={ color } strokeWidth='0.7' strokeMiterlimit='10'/>
    <path d='M21.8312 15.3921V12.3527C21.8312 11.9445 21.501 11.6143 21.0928 11.6143H4.19022H4.18762C3.77942 11.6143 3.44922 11.9445 3.44922 12.3527V15.3921' stroke={ color } strokeWidth='0.7'
          strokeMiterlimit='10'/>
    <path d='M7.2787 8.15641H12.5827V11.6144H6.9043V8.52821C6.9043 8.32281 7.0707 8.15381 7.2787 8.15381V8.15641Z' stroke={ color } strokeWidth='0.7' strokeMiterlimit='10'/>
    <path d='M12.582 8.15649H17.886C18.0914 8.15649 18.2604 8.32549 18.2604 8.53089V11.6145H12.582V8.15649Z' stroke={ color } strokeWidth='0.7' strokeMiterlimit='10'/>
  </svg>
)

export const SingleCol = ({ color = '#0D1536' }) => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <rect x='6' y='6' width='12' height='12' fill={ color }/>
  </svg>
)

export const DoubleCol = ({ color = '#0D1536' }) => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g id='icons/24/2-grid'>
      <rect id='Rectangle 22' x='6' y='6' width='4' height='12' fill={ color }/>
      <rect id='Rectangle 23' x='14' y='6' width='4' height='12' fill={ color }/>
    </g>
  </svg>
)

export const FourCol = ({ color = '#0D1536' }) => (
  <svg width='28' height='24' viewBox='0 0 28 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <rect y='6' width='4' height='12' fill='#C9CACF'/>
    <rect x='8' y='6' width='4' height='12' fill={ color }/>
    <rect x='16' y='6' width='4' height='12' fill={ color }/>
    <rect x='24' y='6' width='4' height='12' fill={ color }/>
  </svg>
)
export const Bath = ({ color = '#0D1536' }) => (
  <svg width='25' height='24' viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M5.90587 17.9065L5.46387 19.6719' stroke={ color } strokeWidth='0.7' strokeMiterlimit='10'/>
    <path d='M18.5732 17.9065L19.0152 19.6719' stroke={ color } strokeWidth='0.7' strokeMiterlimit='10'/>
    <path d='M16.6519 4.14733L17.1693 3.64293C17.6893 3.12293 18.5317 3.12293 19.0491 3.64293C19.3065 3.90033 19.4365 4.23573 19.4391 4.57113V10.9359' stroke={ color } strokeWidth='0.7'
          strokeLinecap='round' strokeLinejoin='round'/>
    <path
      d='M10.755 11.2012H21.9818V11.625C21.9818 11.625 21.3318 11.8304 21.1992 12.5792C21.0588 13.3618 20.9678 14.3108 20.7728 15.421C20.5128 16.9056 19.6392 17.9768 17.814 17.9768H6.6678C4.8426 17.9768 3.969 16.9056 3.709 15.421C3.514 14.3108 3.423 13.3618 3.2826 12.5792C3.15 11.8304 2.5 11.625 2.5 11.625V11.2012H6.5274'
      stroke={ color } strokeWidth='0.7' strokeLinecap='round' strokeLinejoin='round'/>
    <path d='M10.547 10.3562C9.86055 10.3562 8.47995 10.3562 7.58035 10.3562C7.11235 10.3562 6.73535 10.7358 6.73535 11.2038V14.5916H10.547V10.3562Z' stroke={ color } strokeWidth='0.7'
          strokeMiterlimit='10' strokeLinecap='round'/>
    <path d='M16.6517 4.14723L14.2545 3.54663L13.8047 3.99643C13.8047 3.99643 14.2545 4.74523 15.1515 5.64223C16.0485 6.53923 16.8103 6.97863 16.8103 6.97863L17.2471 6.54183L16.6491 4.14723H16.6517Z'
          stroke={ color } strokeWidth='0.7' strokeLinecap='round' strokeLinejoin='round'/>
  </svg>
)

export const DoubleQuote = ({ color = '#0D1536' }) => (
  <svg width='36' height='36' viewBox='0 0 36 36' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M16.88 7V9.112C10.896 9.992 7.20001 13.776 7.20001 19.056C7.20001 21.696 8.08 23.192 8.784 24.248C8.344 21.872 10.016 19.584 12.568 19.584C14.944 19.584 16.792 21.432 16.792 23.808C16.792 26.272 14.768 28.12 12.04 28.12C8.43201 28.12 5 24.776 5 19.056C5 12.456 9.84 7.88 16.88 7ZM30.696 7V9.112C24.712 9.992 21.016 13.776 21.016 19.056C21.016 21.696 21.808 23.192 22.6 24.248C22.16 21.872 23.832 19.584 26.296 19.584C28.76 19.584 30.52 21.432 30.52 23.808C30.52 26.272 28.584 28.12 25.768 28.12C22.16 28.12 18.816 24.776 18.816 19.056C18.816 12.456 23.656 7.88 30.696 7Z'
      fill={ color }/>
  </svg>
)

export const Car = ({ color = '#0D1536' }) => (
  <svg width='25' height='24' viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M4.58261 10.7631H3.97941C3.56081 10.7631 3.22021 10.4589 3.22021 10.0871V9.41113H5.58621' stroke={ color } strokeWidth='0.7' strokeMiterlimit='10'/>
    <path d='M3.49561 16.9719V18.8231C3.49561 18.9323 3.59441 19.0233 3.71921 19.0233H6.31141C6.43361 19.0233 6.53501 18.9349 6.53501 18.8257V16.9745' stroke={ color } strokeWidth='0.7'
          strokeMiterlimit='10'/>
    <path d='M18.6616 16.9719V18.8231C18.6616 18.9323 18.7604 19.0233 18.8852 19.0233H21.4774C21.5996 19.0233 21.701 18.9349 21.701 18.8257V16.9745' stroke={ color } strokeWidth='0.7'
          strokeMiterlimit='10'/>
    <path
      d='M21.701 17.024V12.448C21.701 12.1308 21.5866 11.8188 21.376 11.5562L19.7848 9.9364C19.7848 9.9364 18.6512 8.0462 18.2066 7.1648C17.8504 6.4576 17.0262 6.0026 16.1136 6H9.05718C8.14458 6 7.32038 6.4576 6.96418 7.1648C6.51958 8.0462 5.38598 9.9364 5.38598 9.9364L3.82598 11.5562C3.61278 11.8188 3.50098 12.1308 3.50098 12.448V17.0266H21.7036'
      stroke={ color } strokeWidth='0.7' strokeMiterlimit='10'/>
    <path d='M19.7375 9.85839C19.9481 10.6124 19.2045 11.1948 18.3335 11.1948H6.88314C6.01214 11.1948 5.22174 10.602 5.43234 9.85059' stroke={ color } strokeWidth='0.7' strokeMiterlimit='10'/>
    <path d='M20.632 10.7631H21.217C21.6356 10.7631 21.9762 10.4589 21.9762 10.0871V9.41113H19.4854' stroke={ color } strokeWidth='0.7' strokeMiterlimit='10'/>
    <path d='M3.53467 13.5115H6.29587C7.05767 13.5115 7.67647 14.0627 7.67647 14.7413V15.1521H3.53727' stroke={ color } strokeWidth='0.7' strokeMiterlimit='10'/>
    <path d='M21.7009 13.5115H18.9397C18.1779 13.5115 17.5591 14.0627 17.5591 14.7413V15.1521H21.6983' stroke={ color } strokeWidth='0.7' strokeMiterlimit='10'/>
  </svg>
)

export const Calendar = ({ color = '#0D1536' }) => (
  <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.7228 2.20215H0.974121V15.329H14.7228V2.20215Z" stroke={color} strokeWidth="0.7" strokeMiterlimit="10"/>
    <path d="M1.09326 6.74316H14.743" stroke={color} strokeWidth="0.7" strokeMiterlimit="10"/>
    <path d="M3.92139 0.483398V3.78937" stroke={color} strokeWidth="0.7" strokeMiterlimit="10"/>
    <path d="M11.7959 0.483398V3.78937" stroke={color} strokeWidth="0.7" strokeMiterlimit="10"/>
    <path d="M2.93408 8.73169H3.90428" stroke={color} strokeWidth="0.7" strokeMiterlimit="10"/>
    <path d="M8.81934 8.73169H9.78956" stroke={color} strokeWidth="0.7" strokeMiterlimit="10"/>
    <path d="M5.87646 8.73169H6.84669" stroke={color} strokeWidth="0.7" strokeMiterlimit="10"/>
    <path d="M11.7617 8.73169H12.7319" stroke={color} strokeWidth="0.7" strokeMiterlimit="10"/>
    <path d="M2.93408 10.8281H3.90428" stroke={color} strokeWidth="0.7" strokeMiterlimit="10"/>
    <path d="M8.81934 10.8281H9.78956" stroke={color} strokeWidth="0.7" strokeMiterlimit="10"/>
    <path d="M5.87646 10.8281H6.84669" stroke={color} strokeWidth="0.7" strokeMiterlimit="10"/>
    <path d="M11.7617 10.8281H12.7319" stroke={color} strokeWidth="0.7" strokeMiterlimit="10"/>
    <path d="M2.93408 12.9248H3.90428" stroke={color} strokeWidth="0.7" strokeMiterlimit="10"/>
    <path d="M8.81934 12.9248H9.78956" stroke={color} strokeWidth="0.7" strokeMiterlimit="10"/>
    <path d="M5.87646 12.9248H6.84669" stroke={color} strokeWidth="0.7" strokeMiterlimit="10"/>
    <path d="M11.7617 12.9248H12.7319" stroke={color} strokeWidth="0.7" strokeMiterlimit="10"/>
  </svg>
)

export const Phone = ({ color = '#0D1536' }) => (
  <svg width='25' height='24' viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M7.31402 5H10.6081L12.2551 9.11765L10.1963 10.3529C11.0783 12.1413 12.5256 13.5886 14.3139 14.4706L15.5491 12.4118L19.6667 14.0588V17.3529C19.6667 17.7898 19.4932 18.2087 19.1843 18.5176C18.8754 18.8265 18.4565 19 18.0197 19C14.8074 18.8048 11.7776 17.4406 9.50195 15.165C7.22631 12.8893 5.86221 9.85943 5.66699 6.64706C5.66699 6.21023 5.84052 5.7913 6.14939 5.48241C6.45827 5.17353 6.8772 5 7.31402 5Z'
      stroke={color} strokeWidth='0.823521' strokeLinecap='round' strokeLinejoin='round'/>
  </svg>
)

export const Email = ({ color = '#0D1536' }) => (
  <svg width='25' height='24' viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M20.6685 6.53711H4.66846V17.4629H20.6685V6.53711Z' stroke={color} strokeWidth='0.7' strokeMiterlimit='10'/>
    <path d='M4.74414 8.88086L12.6671 13.8187L20.59 8.88086' stroke={color} strokeWidth='0.7'/>
  </svg>
)

export const LandSize = ({ color = '#0D1536' }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M19.6762 4.39999H7.84766V16.2286H19.6762V4.39999Z" stroke={color} strokeWidth="0.7" strokeMiterlimit="10"/>
    <path d="M5.13721 4.64844V18.9043H19.162" stroke={color} strokeWidth="0.7" strokeLinejoin="round"/>
    <path d="M3.8999 5.62732L5.13717 4.39999L6.34959 5.62235" stroke={color} strokeWidth="0.5" strokeLinejoin="round"/>
    <path d="M18.1831 17.6522L19.4104 18.9044L18.1881 20.1019" stroke={color} strokeWidth="0.5" strokeLinejoin="round"/>
    <path d="M11.7908 12.2161C11.6467 11.7764 11.3435 11.5056 10.7845 11.5056C10.305 11.5056 9.8951 11.7689 9.73858 12.1888V11.5826H9.21436V14.8894H9.76094V13.0683C9.76094 12.3056 10.1361 11.9528 10.6355 11.9528C11.1349 11.9528 11.351 12.2608 11.351 12.8795V14.8919H11.9026V13.0708C11.9026 12.3081 12.2777 11.9553 12.7696 11.9553C13.2616 11.9553 13.4876 12.2633 13.4876 12.882V14.8944H14.0392V12.7652C14.0392 11.9876 13.664 11.5081 12.9013 11.5081C12.4144 11.5081 11.9945 11.7714 11.7908 12.2186V12.2161Z" fill={color}/>
    <path d="M16.5385 12.6087H15.1099C15.1472 12.4223 15.4031 12.2385 15.7062 12.0373L15.7907 11.9826C16.146 11.7565 16.5137 11.523 16.5137 11.0509C16.5137 10.6658 16.2429 10.2534 15.6516 10.2534C15.0603 10.2534 14.7522 10.6956 14.7298 11.1354V11.1851H15.1149V11.1404C15.1373 10.877 15.3087 10.6087 15.6516 10.6087C16.0913 10.6087 16.1261 10.9466 16.1261 11.0509C16.1261 11.3168 15.8776 11.4956 15.5298 11.7192C15.1099 11.9801 14.6826 12.3105 14.6826 12.7751V12.964H16.5435V12.6087H16.5385Z" fill={color}/>
  </svg>
)

export const BasketballCourt = ({ color = '#0D1536' }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.8014 14.8944H20.4164V3.60001H4V14.8944H8.5786" stroke={color} strokeWidth="0.7" strokeMiterlimit="10"/>
    <path d="M7.39307 13.2304H17.0235" stroke={color} strokeWidth="0.7" strokeLinejoin="round"/>
    <path d="M8.58154 13.3214L9.24194 20.7808" stroke={color} strokeWidth="0.7" strokeLinejoin="round"/>
    <path d="M15.8355 13.285L15.1699 20.7912" stroke={color} strokeWidth="0.7" strokeLinejoin="round"/>
    <path d="M11.646 13.2616L15.2964 18.7944" stroke={color} strokeWidth="0.7" strokeLinejoin="round"/>
    <path d="M15.4732 17.3488L13.5596 20.2192L9.00439 13.3214" stroke={color} strokeWidth="0.7" strokeLinejoin="round"/>
    <path d="M12.7695 13.2616L9.11914 18.7944" stroke={color} strokeWidth="0.7" strokeLinejoin="round"/>
    <path d="M8.94287 17.393L10.8565 20.2192L15.4117 13.3214" stroke={color} strokeWidth="0.7" strokeLinejoin="round"/>
    <path d="M15.8353 6.96181H8.55273V11.8472H15.8353V6.96181Z" stroke={color} strokeWidth="0.7" strokeMiterlimit="10"/>
  </svg>
)

export const Library = ({ color = '#0D1536' }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.8301 19.7768V4H7.3877V19.7768" stroke={color} strokeWidth="0.7" strokeMiterlimit="10"/>
    <path d="M16.9723 4.06338L13.6138 4.81842L16.9693 19.7443L20.3278 18.9893L16.9723 4.06338Z" stroke={color} strokeWidth="0.7" strokeMiterlimit="10"/>
    <path d="M7.3878 8.4538H4V19.7768H13.9866V6.8678H10.9732" stroke={color} strokeWidth="0.7" strokeMiterlimit="10"/>
  </svg>
)

export const HomeSecurity = ({ color = '#0D1536' }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.7591 10.9316C13.2115 10.0762 14.1137 9.49384 15.1485 9.49384C16.6409 9.49384 17.8499 10.7028 17.8499 12.1952C17.8499 13.6876 16.6409 14.8966 15.1485 14.8966C14.1007 14.8966 13.1933 14.3012 12.7461 13.4302" stroke={color} strokeWidth="0.7" strokeMiterlimit="10" strokeLinecap="round"/>
    <path d="M11.415 10.838V4H19.2202V20.393H11.415V13.2612" stroke={color} strokeWidth="0.7" strokeMiterlimit="10"/>
    <path d="M5.55957 13.3678H14.8832C15.4448 13.3678 15.9622 12.9908 16.074 12.4396C16.2248 11.6856 15.6528 11.0252 14.9274 11.0252H5.55957V13.3678Z" stroke={color} strokeWidth="0.7" strokeMiterlimit="10"/>
    <path d="M5.94971 9.07518C5.94971 7.35138 7.34851 5.95258 9.07231 5.95258" stroke={color} strokeWidth="0.7" strokeMiterlimit="10"/>
    <path d="M7.90234 9.07521C7.90234 8.42781 8.42754 7.90521 9.07234 7.90521" stroke={color} strokeWidth="0.7" strokeMiterlimit="10"/>
    <path d="M4 9.07522C4 6.27242 6.2724 4.00262 9.0726 4.00262" stroke={color} strokeWidth="0.7" strokeMiterlimit="10"/>
  </svg>
)

export const Lift = ({ color = '#0D1536' }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2.82764 20.2355H21.1228" stroke={color} strokeWidth="0.7" strokeLinejoin="round"/>
    <path d="M5.39795 20.2355V3.84549H18.4303V20.2355" stroke={color} strokeWidth="0.7" strokeMiterlimit="10"/>
    <path d="M11.9751 3.84549V20.2355" stroke={color} strokeWidth="0.7" strokeLinejoin="round"/>
    <path d="M8.68408 7.58455V11.9252" stroke={color} strokeWidth="0.7" strokeLinejoin="round"/>
    <path d="M7.09912 8.89369L8.68643 7.31961L10.2419 8.88839" stroke={color} strokeWidth="0.7" strokeLinejoin="round"/>
  </svg>
)

export const SecondFloor = ({ color = '#0D1536' }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.48389 19.9704V16.1736H12.6071L12.6047 12.2976H16.7279V8.31841H21.0335" stroke={color} strokeWidth="0.7" strokeMiterlimit="10"/>
    <path d="M3.60742 13.3968L15.1562 3.5376" stroke={color} strokeWidth="0.7" strokeMiterlimit="10"/>
    <path d="M8.39517 9.4536V12.2712C8.39517 12.8928 7.89117 13.3968 7.26957 13.3968H6.98877" stroke={color} strokeWidth="0.7" strokeMiterlimit="10"/>
    <path d="M12.6217 5.7912V8.6088C12.6217 9.2304 12.1177 9.7344 11.4961 9.7344H11.2153" stroke={color} strokeWidth="0.7" strokeMiterlimit="10"/>
    <path d="M3.32621 14.2416C3.6377 14.2416 3.89021 13.9891 3.89021 13.6776C3.89021 13.3661 3.6377 13.1136 3.32621 13.1136C3.01472 13.1136 2.76221 13.3661 2.76221 13.6776C2.76221 13.9891 3.01472 14.2416 3.32621 14.2416Z" fill={color}/>
  </svg>
)

export const Gym = ({ color = '#0D1536' }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.31319 11.4448C5.61245 11.4448 5.04492 10.9759 5.04492 10.3977V5.44712C5.04492 4.86894 5.61245 4.39999 6.31319 4.39999C7.01393 4.39999 7.58146 4.86894 7.58146 5.44712V10.3977C7.58146 10.9759 7.01393 11.4448 6.31319 11.4448Z" stroke={color} strokeWidth="0.7" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M17.384 11.4448C16.6832 11.4448 16.1157 10.9759 16.1157 10.3977V5.44712C16.1157 4.86894 16.6832 4.39999 17.384 4.39999C18.0847 4.39999 18.6523 4.86894 18.6523 5.44712V10.3977C18.6523 10.9759 18.0847 11.4448 17.384 11.4448Z" stroke={color} strokeWidth="0.7" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M3.7795 11.0878C3.07875 11.0878 2.51123 10.5202 2.51123 9.81948V6.52888C2.51123 5.82813 3.07875 5.2606 3.7795 5.2606C4.48024 5.2606 5.04777 5.82813 5.04777 6.52888V9.81948C5.04777 10.5202 4.48024 11.0878 3.7795 11.0878Z" stroke={color} strokeWidth="0.7" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M19.9206 11.0878C20.6214 11.0878 21.1889 10.5202 21.1889 9.81948V6.52888C21.1889 5.82813 20.6214 5.2606 19.9206 5.2606C19.2199 5.2606 18.6523 5.82813 18.6523 6.52888V9.81948C18.6523 10.5202 19.2199 11.0878 19.9206 11.0878Z" stroke={color} strokeWidth="0.7" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M7.58154 8.17552H16.1157" stroke={color} strokeWidth="0.7" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M6.31348 20.4V11.4448" stroke={color} strokeWidth="0.7" strokeLinejoin="round"/>
    <path d="M17.3838 20.4V11.4448" stroke={color} strokeWidth="0.7" strokeLinejoin="round"/>
    <path d="M14.8499 17.3146H8.84961V15.3802C8.84961 15.0498 9.11872 14.7807 9.44911 14.7807H14.2477C14.5808 14.7807 14.8472 15.0498 14.8472 15.3802V17.3146H14.8499Z" stroke={color} strokeWidth="0.7" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M9.27051 17.3146V20.3841" stroke={color} strokeWidth="0.7" strokeLinejoin="round"/>
    <path d="M14.4268 17.3146V20.3841" stroke={color} strokeWidth="0.7" strokeLinejoin="round"/>
    <path d="M21.2607 8.17552H22.2972" stroke={color} strokeWidth="0.7" strokeLinejoin="round"/>
    <path d="M2.41239 8.17552H1.3999" stroke={color} strokeWidth="0.7" strokeLinejoin="round"/>
  </svg>
)

export const Mooring = ({ color = '#0D1536' }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M20.4722 20.2416C20.0402 20.3064 19.5914 20.1984 19.2122 19.92L18.497 19.392C17.8826 18.9384 17.0834 18.9384 16.469 19.392L15.7538 19.92C15.1394 20.3736 14.3402 20.3736 13.7258 19.92L13.0106 19.392C12.3962 18.9384 11.597 18.9384 10.9826 19.392L10.2674 19.92C9.65304 20.3736 8.85384 20.3736 8.23944 19.92L7.52424 19.392C6.90984 18.9384 6.11064 18.9384 5.49624 19.392L4.78104 19.92C4.40424 20.1984 3.95544 20.3064 3.52344 20.2416" stroke={color} strokeWidth="0.7" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M17.8798 16.944L19.4446 13.9464H4.60303L6.13663 16.8888" stroke={color} strokeWidth="0.7" strokeMiterlimit="10"/>
    <path d="M20.4722 18.1368C20.0402 18.2016 19.5914 18.0936 19.2122 17.8152L18.497 17.2872C17.8826 16.8336 17.0834 16.8336 16.469 17.2872L15.7538 17.8152C15.1394 18.2688 14.3402 18.2688 13.7258 17.8152L13.0106 17.2872C12.3962 16.8336 11.597 16.8336 10.9826 17.2872L10.2674 17.8152C9.65304 18.2688 8.85384 18.2688 8.23944 17.8152L7.52424 17.2872C6.90984 16.8336 6.11064 16.8336 5.49624 17.2872L4.78104 17.8152C4.40424 18.0936 3.95544 18.2016 3.52344 18.1368" stroke={color} strokeWidth="0.7" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M11.9327 3.4584V12.0696H5.48633L11.9327 3.4584Z" stroke={color} strokeWidth="0.7" strokeMiterlimit="10"/>
    <path d="M11.9326 12.0696V14.0616" stroke={color} strokeWidth="0.7" strokeMiterlimit="10"/>
    <path d="M13.0176 4.79761V12.0696H18.5616L13.0176 4.79761Z" stroke={color} strokeWidth="0.7" strokeMiterlimit="10"/>
    <path d="M13.0176 12.0888V13.764" stroke={color} strokeWidth="0.7" strokeMiterlimit="10"/>
  </svg>
)

export const Park = ({ color = '#0D1536' }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.54102 7.53841V20.5152" stroke={color} strokeWidth="0.7" strokeMiterlimit="10"/>
    <path d="M4.8623 11.5512L7.5407 14.2104" stroke={color} strokeWidth="0.7" strokeMiterlimit="10"/>
    <path d="M9.62424 10.0512L7.48584 12.1704" stroke={color} strokeWidth="0.7" strokeMiterlimit="10"/>
    <path d="M10.7593 20.5152V18.8112" stroke={color} strokeWidth="0.7" strokeMiterlimit="10"/>
    <path d="M20.8296 20.5152V18.8112" stroke={color} strokeWidth="0.7" strokeMiterlimit="10"/>
    <path d="M20.8297 17.22H10.7593V18.8112H20.8297V17.22Z" stroke={color} strokeWidth="0.7" strokeMiterlimit="10"/>
    <path d="M11.7744 17.22V15.54C11.7744 14.9232 12.2736 14.424 12.8904 14.424H18.696C19.3128 14.424 19.812 14.9232 19.812 15.54V17.22" stroke={color} strokeWidth="0.7" strokeMiterlimit="10"/>
    <path d="M7.54073 16.608C9.95753 16.608 11.9111 14.652 11.9111 12.2376C11.9111 10.7064 11.1239 9.36241 9.93113 8.58001C10.1903 8.15521 10.3415 7.65841 10.3415 7.12321C10.3415 5.57521 9.08633 4.32001 7.53833 4.32001C5.99033 4.32001 4.73513 5.57521 4.73513 7.12321C4.73513 7.65841 4.88633 8.15521 5.14553 8.58001C3.95513 9.36001 3.16553 10.7064 3.16553 12.2376C3.16553 14.652 5.12153 16.608 7.53593 16.608C8.33033 16.608 9.07433 16.3968 9.71513 16.0272" stroke={color} strokeWidth="0.7" strokeMiterlimit="10"/>
  </svg>
)

export const Helipad = ({ color = '#0D1536' }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.4668 11.4896C5.0794 12.2592 4.2812 12.7896 3.3582 12.7896C2.0556 12.7896 1 11.734 1 10.4314C1 9.12884 2.0556 8.07324 3.3582 8.07324C4.6608 8.07324 5.7164 9.12884 5.7164 10.4314" stroke={color} strokeWidth="0.7" strokeMiterlimit="10"/>
    <path d="M15.5654 8.51001L16.8264 12.6336C16.8264 12.6336 17.1904 13.8686 18.1316 13.8686H21.7846" stroke={color} strokeWidth="0.7" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M21.5869 12.8026C20.5755 10.7876 17.8663 8.85581 15.5653 8.51001L13.5763 8.21101C11.7849 7.94321 10.2457 8.87661 9.65288 10.395H3.08008L9.52808 13.281C9.99868 15.1036 11.6601 16.4634 13.6231 16.4634H19.1897C21.5167 16.4634 22.5957 14.815 21.5843 12.8L21.5869 12.8026Z" stroke={color} strokeWidth="0.7" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M10.7656 18.2392H20.8562C21.943 18.2392 22.658 17.4436 22.658 17.4436" stroke={color} strokeWidth="0.7" strokeMiterlimit="10"/>
    <path d="M14.3433 8.2526V5" stroke={color} strokeWidth="0.7" strokeMiterlimit="10"/>
    <path d="M5.9248 6.08423H14.6634" stroke={color} strokeWidth="0.7" strokeMiterlimit="10"/>
    <path d="M14.5439 6.08423H22.7652" stroke={color} strokeWidth="0.7" strokeMiterlimit="10"/>
    <path d="M13.938 16.6038L14.4086 18.013" stroke={color} strokeWidth="0.7" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M18.5479 16.6038L19.0159 18.013" stroke={color} strokeWidth="0.7" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
)

export const Study = ({ color = '#0D1536' }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3 20.1562V10.7702H21.0024V19.956H14.4504V10.7702" stroke={color} strokeWidth="0.7" strokeMiterlimit="10"/>
    <path d="M14.5464 14.5558H21.0022" stroke={color} strokeWidth="0.7" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M18.2724 16.0794H17.2324" stroke={color} strokeWidth="0.7" strokeLinejoin="round"/>
    <path d="M18.2724 12.0676H17.2324" stroke={color} strokeWidth="0.7" strokeLinejoin="round"/>
    <path d="M13.0933 4.2H4.91113V8.8176H13.0933V4.2Z" stroke={color} strokeWidth="0.7" strokeMiterlimit="10"/>
    <path d="M9.00342 10.7286V8.95279" stroke={color} strokeWidth="0.7" strokeMiterlimit="10"/>
  </svg>
)

export const Sauna = ({ color = '#0D1536' }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18.6324 3.84552H5.6001V20.2355H18.6324V3.84552Z" stroke={color} strokeWidth="0.713444" strokeMiterlimit="10"/>
    <path d="M10.0571 8.42732V20.2355" stroke={color} strokeWidth="0.713444" strokeLinejoin="round"/>
    <path d="M14.3839 13.3032C16.064 13.3032 17.426 11.9412 17.426 10.2611C17.426 8.58095 16.064 7.21893 14.3839 7.21893C12.7038 7.21893 11.3418 8.58095 11.3418 10.2611C11.3418 11.9412 12.7038 13.3032 14.3839 13.3032Z" stroke={color} strokeWidth="0.713444" strokeMiterlimit="10" strokeLinecap="round"/>
    <path d="M12.8235 11.5172C14.0292 10.2585 11.586 10.2956 13.0752 9.00238" stroke={color} strokeWidth="0.713444" strokeMiterlimit="10"/>
    <path d="M14.3342 11.5172C15.5399 10.2585 13.0967 10.2956 14.586 9.00238" stroke={color} strokeWidth="0.713444" strokeMiterlimit="10"/>
    <path d="M15.8415 11.5172C17.0473 10.2585 14.604 10.2956 16.0933 9.00238" stroke={color} strokeWidth="0.713444" strokeMiterlimit="10"/>
    <path d="M12.0048 15.1158H10.9448" stroke={color} strokeWidth="0.713444" strokeLinejoin="round"/>
    <path d="M10.8364 3.84552C10.1369 3.84552 8.72975 3.84552 7.81288 3.84552C7.3359 3.84552 6.95166 4.23241 6.95166 4.70941V8.42729H10.8364V3.84552Z" stroke={color} strokeWidth="0.713444" strokeMiterlimit="10" strokeLinecap="round"/>
  </svg>
)

export const Nursery = ({ color = '#0D1536' }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M21.0938 8.10803V20.4996" stroke={color} strokeWidth="0.7" strokeLinejoin="round"/>
    <path d="M18.3848 11.2488V18.1752" stroke={color} strokeWidth="0.7" strokeLinejoin="round"/>
    <path d="M15.813 11.2488V18.1752" stroke={color} strokeWidth="0.7" strokeLinejoin="round"/>
    <path d="M13.2417 11.2488V18.1752" stroke={color} strokeWidth="0.7" strokeLinejoin="round"/>
    <path d="M10.6704 11.2488V18.1752" stroke={color} strokeWidth="0.7" strokeLinejoin="round"/>
    <path d="M8.09912 11.2488V18.1752" stroke={color} strokeWidth="0.7" strokeLinejoin="round"/>
    <path d="M5.52783 11.2462V18.1752" stroke={color} strokeWidth="0.7" strokeLinejoin="round"/>
    <path d="M3 8.10803V20.4996" stroke={color} strokeWidth="0.7" strokeLinejoin="round"/>
    <path d="M3 11.3398H21.0934" stroke={color} strokeWidth="0.7" strokeLinejoin="round"/>
    <path d="M3 18.1752H21.0934" stroke={color} strokeWidth="0.7" strokeLinejoin="round"/>
    <path d="M12.0483 4V6.3738" stroke={color} strokeWidth="0.7" strokeLinejoin="round"/>
    <path d="M9.29248 8.08201V5.32861H14.8019V8.08201" stroke={color} strokeWidth="0.7" strokeMiterlimit="10"/>
    <path d="M12.0481 7.94944C12.4831 7.94944 12.8359 7.59673 12.8359 7.16164C12.8359 6.72655 12.4831 6.37384 12.0481 6.37384C11.613 6.37384 11.2603 6.72655 11.2603 7.16164C11.2603 7.59673 11.613 7.94944 12.0481 7.94944Z" stroke={color} strokeWidth="0.7" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M14.8015 9.65763C15.2366 9.65763 15.5893 9.30492 15.5893 8.86983C15.5893 8.43474 15.2366 8.08203 14.8015 8.08203C14.3664 8.08203 14.0137 8.43474 14.0137 8.86983C14.0137 9.30492 14.3664 9.65763 14.8015 9.65763Z" stroke={color} strokeWidth="0.7" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M9.29219 9.65763C9.72728 9.65763 10.08 9.30492 10.08 8.86983C10.08 8.43474 9.72728 8.08203 9.29219 8.08203C8.8571 8.08203 8.50439 8.43474 8.50439 8.86983C8.50439 9.30492 8.8571 9.65763 9.29219 9.65763Z" stroke={color} strokeWidth="0.7" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
)

export const Cinema = ({ color = '#0D1536' }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.0709 17.4554V16.4986C10.0709 15.9708 9.60294 15.5418 9.02574 15.5418H6.93534C6.35814 15.5418 5.89014 15.9708 5.89014 16.4986V17.4554H10.0709Z" stroke={color} strokeWidth="0.7" strokeMiterlimit="10" strokeLinecap="round"/>
    <path d="M14.2516 17.4554V16.4986C14.2516 15.9708 13.7836 15.5418 13.2064 15.5418H11.116C10.5388 15.5418 10.0708 15.9708 10.0708 16.4986V17.4554H14.2516Z" stroke={color} strokeWidth="0.7" strokeMiterlimit="10" strokeLinecap="round"/>
    <path d="M18.4298 17.4554V16.4986C18.4298 15.9708 17.9618 15.5418 17.3846 15.5418H15.2942C14.717 15.5418 14.249 15.9708 14.249 16.4986V17.4554H18.4298Z" stroke={color} strokeWidth="0.7" strokeMiterlimit="10" strokeLinecap="round"/>
    <path d="M7.98061 20.5442V19.5874C7.98061 19.0596 7.51261 18.6306 6.93541 18.6306H4.84501C4.26781 18.6306 3.7998 19.0596 3.7998 19.5874V20.5442H7.98061Z" stroke={color} strokeWidth="0.7" strokeMiterlimit="10" strokeLinecap="round"/>
    <path d="M12.1613 20.5442V19.5874C12.1613 19.0596 11.6933 18.6306 11.1161 18.6306H9.02567C8.44847 18.6306 7.98047 19.0596 7.98047 19.5874V20.5442H12.1613Z" stroke={color} strokeWidth="0.7" strokeMiterlimit="10" strokeLinecap="round"/>
    <path d="M16.3395 20.5442V19.5874C16.3395 19.0596 15.8715 18.6306 15.2943 18.6306H13.2039C12.6267 18.6306 12.1587 19.0596 12.1587 19.5874V20.5442H16.3395Z" stroke={color} strokeWidth="0.7" strokeMiterlimit="10" strokeLinecap="round"/>
    <path d="M20.5202 20.5442V19.5874C20.5202 19.0596 20.0522 18.6306 19.475 18.6306H17.3846C16.8074 18.6306 16.3394 19.0596 16.3394 19.5874V20.5442H20.5202Z" stroke={color} strokeWidth="0.7" strokeMiterlimit="10" strokeLinecap="round"/>
    <path d="M20.5178 3.60001H3.7998V13.5528H20.5178V3.60001Z" stroke={color} strokeWidth="0.7" strokeMiterlimit="10"/>
  </svg>
)

export const Cellar = ({ color = '#0D1536' }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.6954 20.2735V12.3539C14.6954 10.8953 14.5011 10.6327 13.7317 9.95926C13.1902 9.48606 12.9649 9.17926 12.9649 8.44866V4.00006H11.2344V8.44866C11.2344 9.17926 11.009 9.48606 10.4676 9.95926C9.70079 10.6327 9.50391 10.8953 9.50391 12.3539V20.2735H14.6928H14.6954Z" stroke={color} strokeWidth="0.7" strokeMiterlimit="10"/>
    <path d="M14.6947 13.4666H11.6689V17.806H14.6947" stroke={color} strokeWidth="0.7" strokeMiterlimit="10"/>
    <path d="M21.3999 20.2734V12.3538C21.3999 10.8952 21.2057 10.6326 20.4363 9.9592C19.8948 9.486 19.6695 9.1792 19.6695 8.4486V4H17.939V8.4486C17.939 9.1792 17.7136 9.486 17.1722 9.9592C16.4054 10.6326 16.2085 10.8952 16.2085 12.3538V20.2734H21.3974H21.3999Z" stroke={color} strokeWidth="0.7" strokeMiterlimit="10"/>
    <path d="M21.3998 13.4666H18.374V17.806H21.3998" stroke={color} strokeWidth="0.7" strokeMiterlimit="10"/>
    <path d="M7.99125 20.2734V12.3538C7.99125 10.8952 7.79696 10.6326 7.02757 9.9592C6.48615 9.486 6.26077 9.1792 6.26077 8.4486V4H4.53029V8.4486C4.53029 9.1792 4.30491 9.486 3.76349 9.9592C2.99669 10.6326 2.7998 10.8952 2.7998 12.3538V20.2734H7.98866H7.99125Z" stroke={color} strokeWidth="0.7" strokeMiterlimit="10"/>
    <path d="M7.99109 13.4666H4.96533V17.806H7.99109" stroke={color} strokeWidth="0.7" strokeMiterlimit="10"/>
  </svg>
)

export const TennisCourt = ({ color = '#0D1536' }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M21.8002 5.95981H2.20068V18.1512H21.8002V5.95981Z" stroke={color} strokeWidth="0.7" strokeMiterlimit="10"/>
    <path d="M12.0015 19.5084V4.60001" stroke={color} strokeWidth="0.7" strokeMiterlimit="10"/>
    <path d="M21.7997 15.8554H2.2002" stroke={color} strokeWidth="0.7" strokeMiterlimit="10"/>
    <path d="M2.2002 8.25302H21.7997" stroke={color} strokeWidth="0.7" strokeMiterlimit="10"/>
    <path d="M17.478 15.8554V8.25302" stroke={color} strokeWidth="0.7" strokeMiterlimit="10"/>
    <path d="M12.001 12.0542H17.4778" stroke={color} strokeWidth="0.7" strokeMiterlimit="10"/>
    <path d="M6.52197 15.8554V8.25302" stroke={color} strokeWidth="0.7" strokeMiterlimit="10"/>
    <path d="M12.0014 12.0542H6.52197" stroke={color} strokeWidth="0.7" strokeMiterlimit="10"/>
  </svg>
)

export const ChargingStation = ({ color = '#0D1536' }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.33838 20.2448V5.1882C4.33838 4.5356 4.87398 4 5.52658 4H13.0562C13.7114 4 14.2444 4.533 14.2444 5.1882V20.2448" stroke={color} strokeWidth="0.7" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M3.3999 20.2448H15.1857" stroke={color} strokeWidth="0.7" strokeLinejoin="round"/>
    <path d="M14.2446 16.6776H15.4328C15.8696 16.6776 16.2258 17.0312 16.2258 17.4706V19.4518C16.2258 19.8886 16.5794 20.2448 17.0188 20.2448H17.8118C18.2486 20.2448 18.6048 19.8912 18.6048 19.4518V11.527" stroke={color} strokeWidth="0.7" strokeLinejoin="round"/>
    <path d="M17.4141 5.18817V7.16937" stroke={color} strokeWidth="0.7" strokeLinejoin="round"/>
    <path d="M19.793 5.18817V7.16937" stroke={color} strokeWidth="0.7" strokeLinejoin="round"/>
    <path d="M8.64408 17.5095L11.2389 12.3199H7.34668L9.94148 7.12775" stroke={color} strokeWidth="0.7" strokeMiterlimit="10"/>
    <path d="M20.5856 9.9436C20.5856 10.8198 19.699 11.5296 18.6044 11.5296C17.5098 11.5296 16.6232 10.8198 16.6232 9.9436V8.7554L16.228 8.3602V7.172H20.9834V8.3602L20.5882 8.7554V9.9436H20.5856Z" stroke={color} strokeWidth="0.7" strokeLinejoin="round"/>
  </svg>
)

export const Pool = ({ color = '#0D1536' }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.6299 5.04188C12.6299 4.18031 13.3798 3.81866 13.8531 3.81866C14.3637 3.81866 15.0763 4.18031 15.0763 5.04188V16.8113" stroke={color} strokeWidth="0.715931" strokeLinejoin="round"/>
    <path d="M7.0083 5.04188C7.0083 4.18031 7.75819 3.81866 8.23152 3.81866C8.74209 3.81866 9.45475 4.18031 9.45475 5.04188V16.9257" stroke={color} strokeWidth="0.715931" strokeLinejoin="round"/>
    <path d="M15.0766 7.37396H9.45508" stroke={color} strokeWidth="0.715931" strokeLinejoin="round"/>
    <path d="M15.0766 10.3974L9.2876 10.3921" stroke={color} strokeWidth="0.715931" strokeLinejoin="round"/>
    <path d="M21.5702 16.9709C21.0915 17.0427 20.5943 16.923 20.1741 16.6145L19.3817 16.0295C18.7009 15.5269 17.8154 15.5269 17.1347 16.0295L16.3422 16.6145C15.6615 17.1171 14.776 17.1171 14.0952 16.6145L13.3028 16.0295C12.622 15.5269 11.7365 15.5269 11.0558 16.0295L10.2633 16.6145C9.58257 17.1171 8.69706 17.1171 8.01631 16.6145L7.22387 16.0295C6.54312 15.5269 5.65762 15.5269 4.97686 16.0295L4.18443 16.6145C3.76694 16.923 3.26967 17.0427 2.79102 16.9709" stroke={color} strokeWidth="0.715931" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M21.5702 20.0981C21.0915 20.1699 20.5943 20.0502 20.1741 19.7417L19.3817 19.1567C18.7009 18.6541 17.8154 18.6541 17.1347 19.1567L16.3422 19.7417C15.6615 20.2443 14.776 20.2443 14.0952 19.7417L13.3028 19.1567C12.622 18.6541 11.7365 18.6541 11.0558 19.1567L10.2633 19.7417C9.58257 20.2443 8.69706 20.2443 8.01631 19.7417L7.22387 19.1567C6.54312 18.6541 5.65762 18.6541 4.97686 19.1567L4.18443 19.7417C3.76694 20.0502 3.26967 20.1699 2.79102 20.0981" stroke={color} strokeWidth="0.715931" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M15.0737 13.4369L9.28467 13.4315" stroke={color} strokeWidth="0.715931" strokeLinejoin="round"/>
  </svg>
)

export const MultiCarGarage = ({ color = '#0D1536' }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.9634 9.5544H18.0338" stroke={color} strokeWidth="0.7" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M20.4841 9.5544C20.4841 8.8776 19.9345 8.328 19.2577 8.328C18.5809 8.328 18.0312 8.8776 18.0312 9.5544" stroke={color} strokeWidth="0.7" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M18.0337 9.5544C18.0337 10.2312 18.5833 10.7808 19.2601 10.7808C19.9369 10.7808 20.4865 10.2312 20.4865 9.5544" stroke={color} strokeWidth="0.7" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M20.484 9.55441C20.8992 9.50161 21.336 9.45601 21.7104 9.26161V7.62961C21.7104 7.27921 21.516 6.93841 21.18 6.79441C20.1144 6.34081 19.3248 6.21121 17.9304 5.97841C16.4712 4.92001 15.1392 4.24561 13.7952 4.24561H12.0552C10.644 4.24561 9.40077 5.17921 8.70477 5.56561C8.07597 5.91601 7.46397 6.03361 7.05837 6.03601C6.88557 6.03601 6.56637 6.25201 6.61197 6.44401L6.59277 8.27761V8.87521C6.59277 9.20641 6.86637 9.39841 7.21917 9.47281C7.35597 9.50161 7.57437 9.52081 7.79997 9.53281" stroke={color} strokeWidth="0.7" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M11.8247 14.3064H15.8927" stroke={color} strokeWidth="0.7" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M18.3434 14.3064C18.3434 13.6296 17.7938 13.08 17.117 13.08C16.4402 13.08 15.8906 13.6296 15.8906 14.3064" fill="white"/>
    <path d="M18.3434 14.3064C18.3434 13.6296 17.7938 13.08 17.117 13.08C16.4402 13.08 15.8906 13.6296 15.8906 14.3064" stroke={color} strokeWidth="0.7" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M15.8926 14.3064C15.8926 14.9832 16.4422 15.5328 17.119 15.5328C17.7958 15.5328 18.3454 14.9832 18.3454 14.3064" fill="white"/>
    <path d="M15.8926 14.3064C15.8926 14.9832 16.4422 15.5328 17.119 15.5328C17.7958 15.5328 18.3454 14.9832 18.3454 14.3064" stroke={color} strokeWidth="0.7" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M18.3433 14.3064C18.7585 14.2536 19.1953 14.208 19.5697 14.0136V12.3816C19.5697 12.0312 19.3753 11.6904 19.0393 11.5464C17.9737 11.0928 17.1841 10.9632 15.7897 10.7304C14.3305 9.672 12.9985 8.9976 11.6545 8.9976H9.91451C8.50331 8.9976 7.26011 9.9312 6.56411 10.3176C5.93531 10.668 5.32331 10.7856 4.91771 10.788C4.74491 10.788 4.40891 10.968 4.45451 11.1624L4.44971 13.0296V13.6272C4.44971 13.9584 4.72331 14.1504 5.07611 14.2248C5.46251 14.3064 5.77211 14.3088 5.77211 14.3088" stroke={color} strokeWidth="0.7" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M6.80371 19.0584H13.7517" stroke={color} strokeWidth="0.7" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M13.752 19.0584C13.752 19.7352 14.3016 20.2848 14.9784 20.2848C15.6552 20.2848 16.2048 19.7352 16.2048 19.0584C16.2048 18.3816 15.6552 17.832 14.9784 17.832C14.3016 17.832 13.752 18.3816 13.752 19.0584Z" stroke={color} strokeWidth="0.7" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M4.35352 19.0584C4.35352 19.7352 4.90312 20.2848 5.57992 20.2848C6.25672 20.2848 6.80632 19.7352 6.80632 19.0584C6.80632 18.3816 6.25672 17.832 5.57992 17.832C4.90312 17.832 4.35352 18.3816 4.35352 19.0584Z" stroke={color} strokeWidth="0.7" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M16.2022 19.0584C16.6174 19.0056 17.0542 18.96 17.4286 18.7656V17.1336C17.4286 16.7832 17.2342 16.4424 16.8982 16.2984C15.8326 15.8448 15.043 15.7152 13.6486 15.4824C12.1894 14.424 10.8574 13.7496 9.51339 13.7496H7.77339C6.36219 13.7496 5.11899 14.6832 4.42299 15.0696C3.79419 15.42 3.18219 15.5376 2.77659 15.54C2.60379 15.54 2.30139 15.804 2.34699 15.996L2.30859 17.7816V18.3792C2.30859 18.7104 2.58219 18.9024 2.93499 18.9768C3.32139 19.0584 4.35099 19.0608 4.35099 19.0608" stroke={color} strokeWidth="0.7" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
)


export const Foundation = ({ color = '#FFFFFF' }) => (
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
	 viewBox="0 0 496.1 164.4">
<g>
	<g>
		<path d="M112.1,39.6c-5.4-0.1-8.7,3.4-8.7,9.5c-0.1,8.2-0.1,16.5,0,24.7c0.2,24.1-12.5,39.7-32.8,50.4c-0.9,0.4-1.9,0.5-3.3,0.9
			c-0.2-1.7-0.4-2.9-0.5-4c-1.1-18.4-15.2-34.3-32.3-37.7c-1.2-0.2-2.8-2.3-2.9-3.6c-0.4-6.7-0.4-13.4-0.4-20c0-5.9-3.4-10-8.3-10
			c-5.3,0-8.8,3.7-8.8,9.6c-0.1,12.5,0.1,25,0,37.5c-0.3,29.3,24.6,54.6,55.5,53.4c27.2-1,50.6-24,50.9-51.3
			c0.2-16.6,0.1-33.2,0-49.8C120.3,43.3,117.2,39.6,112.1,39.6"/>
		<path d="M61.9,71.3c0-2.9,2.4-5.3,5.3-5.3c2.9,0,5.3,2.4,5.3,5.3v7.2H98v-1.7V47.2V34.6c0-4.7-3.8-8.6-8.6-8.6s-8.6,3.8-8.6,8.6
			v17.4h-5.1V22.7c0-4.7-3.8-8.6-8.6-8.6c-4.7,0-8.6,3.8-8.6,8.6v29.3h-5.1V34.6c0-4.7-3.8-8.6-8.6-8.6c-4.7,0-8.6,3.8-8.6,8.6v12.6
			v29.7v1.7h25.5V71.3z"/>
	</g>
	<polygon points="149.1,101.7 153.5,101.7 153.5,78.1 178.8,78.1 178.8,74.2 153.5,74.2 153.5,53.4 181.1,53.4 181.1,49.4 
		149.1,49.4 	"/>
	<g>
		<path d="M201.9,64.2c-9.7,0-16.8,7.5-16.8,19.2c0,11.8,7.1,19.2,16.8,19.2c9.7,0,16.8-7.4,16.8-19.2
			C218.6,71.7,211.5,64.2,201.9,64.2 M201.9,98.9c-7.8,0-12.6-5.9-12.6-15.5c0-9.4,4.8-15.5,12.6-15.5c7.8,0,12.6,6,12.6,15.5
			C214.5,92.9,209.7,98.9,201.9,98.9"/>
		<path d="M250.2,85.5c0,9.6-4.6,13.8-10.8,13.8c-6.3,0-9.4-3.9-9.4-11.2V65.1h-4.3v23.7c0,8.9,4.6,13.8,12.9,13.8
			c5.6,0,10.1-2.9,11.9-7.8v7h4V65.1h-4.2V85.5z"/>
		<path d="M280.2,64.2c-5.7,0-10.5,3.1-12.2,7.9v-7h-4v36.6h4.2V81.4c0-9.6,4.9-13.5,11.1-13.5c6.3,0,9.6,3.8,9.6,11.1v22.8h4.2
			V78.1C293.1,69.3,288.3,64.2,280.2,64.2"/>
		<path d="M329.2,71.9c-1.6-3.7-5.8-7.7-12.5-7.7c-9.2,0-16.5,7.4-16.5,19.2c0,11.8,7.2,19.2,16.5,19.2c6.3,0,10.8-3.8,12.7-7.5v6.7
			h4V47.8h-4.2V71.9z M329.5,83.8c0,9-4.5,15.2-12.3,15.2c-7.9,0-12.6-6.4-12.6-15.6c0-9.2,4.7-15.6,12.6-15.6
			c7.8,0,12.3,6.2,12.3,15.2V83.8z"/>
		<path d="M369.4,76.6c0-8.4-5.4-12.4-13.7-12.4c-7,0-12.7,4-13.5,11.2h4.2c0.5-4.7,3.9-7.5,9.3-7.5c5.5,0,9.5,2.6,9.5,9.2v2.4
			l-12.3,1.6c-7.4,1-12.4,4-12.4,10.8c0,6.9,5.6,10.5,11.6,10.5c6.3,0,11.5-2.9,13-7.2c0,1.8,0.2,4.4,0.7,6.4h4.4
			c-0.7-2.1-1-4.5-1-6.7V76.6z M365.2,87.2c0,7.8-6.4,11.7-12.5,11.7c-5.1,0-7.8-3.1-7.8-6.8c0-5.6,4.2-6.9,9.2-7.6l11.1-1.2V87.2z"
			/>
		<path d="M383.4,93V68.5h10.2v-3.4h-10.1v-9.4h-4.2v9.4h-5.6v3.4h5.6v24.1c0,6.5,3.3,9.7,9.4,9.7c1.2,0,3.3-0.1,5.9-1v-3.7
			c-2.5,0.8-4.2,1-5.6,1C385.5,98.6,383.4,97.1,383.4,93"/>
	</g>
	<rect x="400.6" y="65.1" width="4.2" height="36.6"/>
	<rect x="400.1" y="50.2" width="5.2" height="6.4"/>
	<g>
		<path d="M428.8,64.2c-9.7,0-16.8,7.5-16.8,19.2c0,11.8,7.1,19.2,16.8,19.2c9.7,0,16.8-7.4,16.8-19.2
			C445.6,71.7,438.5,64.2,428.8,64.2 M428.8,98.9c-7.8,0-12.6-5.9-12.6-15.5c0-9.4,4.8-15.5,12.6-15.5c7.8,0,12.6,6,12.6,15.5
			C441.4,92.9,436.6,98.9,428.8,98.9"/>
		<path d="M469.2,64.2c-5.7,0-10.5,3.1-12.2,7.9v-7h-4v36.6h4.2V81.4c0-9.6,4.9-13.5,11.1-13.5c6.3,0,9.6,3.8,9.6,11.1v22.8h4.2
			V78.1C482.1,69.3,477.3,64.2,469.2,64.2"/>
		<path d="M455.4,128.3h0.3c1.2-5.2,2.1-5.9,4.1-5.9h0.4v13c0,1-0.3,1.2-2.2,1.3v0.2h7.7v-0.2c-1.9-0.1-2.2-0.3-2.2-1.3v-13h0.4
			c2,0,2.8,0.7,4.1,5.9h0.3V122h-12.9V128.3z"/>
		<path d="M447,122.3c1.3,0.1,1.5,0.3,1.5,1.2v12.1c0,0.9-0.2,1.1-1.5,1.2v0.2h6.4v-0.2c-1.3-0.1-1.5-0.3-1.5-1.2v-12.1
			c0-0.9,0.2-1.1,1.5-1.2V122H447V122.3z"/>
		<path d="M481.9,131.4c-1,3.7-2.6,5.1-5.2,5.1h-1.5v-6.9h0.6c1.8,0,2.5,0.6,2.9,3.2h0.2v-6.9h-0.2c-0.5,2.6-1.1,3.4-2.9,3.4h-0.6
			v-6.8h1.5c2.6,0,4.3,1.3,5.3,4.7h0.1h0.2l-0.2-5.1h-11.6v0.3c1.3,0.1,1.5,0.3,1.5,1.2v12.1c0,0.9-0.2,1.1-1.5,1.2v0.2h4h7.6
			l0.2-5.5H482H481.9z"/>
		<path d="M438.7,122.3c1.3,0.1,1.5,0.3,1.5,1.2v5.4v0.4h-6.6v-0.4v-5.4c0-0.9,0.2-1.1,1.5-1.2V122h-6.4v0.3
			c1.3,0.1,1.5,0.3,1.5,1.2v12.1c0,0.9-0.2,1.1-1.5,1.2v0.2h6.4v-0.2c-1.3-0.1-1.5-0.3-1.5-1.2v-5.9h6.6v5.9c0,0.9-0.2,1.1-1.5,1.2
			v0.2h6.4v-0.2c-1.3-0.1-1.5-0.3-1.5-1.2v-12.1c0-0.9,0.2-1.1,1.5-1.2V122h-6.4V122.3z"/>
		<path d="M422,122.3c2.1,0.3,2.5,1.8,1.3,5.7l-2.4,7.8l-3.2-12.3l0-0.1c-0.2-1,0.3-1.1,1.6-1.2V122h-1.9h0h-2.5h-2v0.3
			c1.2,0.1,1.8,0.1,1.6,1l-2.1,8.1c-0.3,1-0.5,1.8-0.7,2.8l-0.4,1.7l-3.1-10.9c-0.7-2.3-0.5-2.5,1.2-2.6V122h-6.5v0.3
			c0.8,0.1,1.1,0.3,1.3,1.2l4.3,13.5h2.8l2.2-8.1c0.3-1.3,0.7-2.5,1-4.2h0.2c0.4,1.7,0.7,2.8,1.1,4.1l2.3,8.1h2.9l3.2-10.3
			c0.8-2.6,1.5-3.9,2.4-4.3V122H422V122.3z"/>
		<path d="M316.3,132.3c-0.5-1.7-2.4-2.4-5.2-2.6v-0.1c3.8-0.2,5.8-1.9,5.8-4.1c0-2.4-1.4-3.5-7.1-3.5h-5.6v0.3
			c1.3,0.1,1.5,0.3,1.5,1.2v12.1c0,0.9-0.2,1.1-1.5,1.2v0.2h6.4v-0.2c-1.3-0.1-1.5-0.3-1.5-1.2v-5.7h0.5c1.6,0,3.1,0.5,3.7,2.3
			l0.3,1c0.1,0.4,0.2,0.8,0.4,1.2c0.6,1.5,0.9,2.1,1.3,2.7h4.2v-0.2C318.8,136.5,317.5,136,316.3,132.3 M309,129.5v-7.2h1.6
			c2.8,0.2,3.5,1.2,3.5,3.3c0,2.1-2.2,3.8-4.4,3.8H309z"/>
		<path d="M329.4,128.3l-2.5-0.8c-0.3-0.1-0.6-0.2-0.8-0.3c-0.1,0-0.2-0.1-0.2-0.1h0c-1.6-0.6-2.1-1.3-2.1-2.4c0-1.6,1-2.5,3-2.5
			c2.4,0,4.3,1.7,5.6,4.7c0.1,0.2,0.1,0.4,0.2,0.5h0.3V122h-0.2c-1.3,1.5-2.4-0.2-6-0.2c-3.5,0-5.3,1.7-5.3,4.4
			c0,2.3,1.3,3.4,3.8,4.3l2.4,0.8c2.1,0.7,3.5,1.3,3.9,2.4c0,0.1,0.1,0.3,0.1,0.4c0,1.7-1.7,2.6-3.8,2.6c-2.5,0-3.9-1.7-5.2-4.7
			c-0.1-0.3-0.3-0.6-0.4-1h-0.3v6h0.2c1.3-1.6,1.9,0.3,5.6,0.3c3.5,0,6.2-1.7,6.2-4.5C333.9,130.3,331.9,129.1,329.4,128.3"/>
		<path d="M392.5,136.5h-1.9v-12.9c0-1,0.3-1.2,2.2-1.3V122h-7v0.3c1.3,0.1,1.5,0.3,1.5,1.2v12.1c0,0.9-0.2,1.1-1.5,1.2v0.2h11.6
			l0.2-6.2h-0.3C396.3,135.8,395.2,136.5,392.5,136.5"/>
		<path d="M378.8,136.5h-1.9v-12.9c0-1,0.3-1.2,2.2-1.3V122h-7v0.3c1.3,0.1,1.5,0.3,1.5,1.2v12.1c0,0.9-0.2,1.1-1.5,1.2v0.2h11.6
			l0.2-6.2h-0.3C382.6,135.8,381.4,136.5,378.8,136.5"/>
		<path d="M345.7,122.3c1.3,0.1,1.5,0.3,1.5,1.2v5.4v0.4h-6.6v-0.4v-5.4c0-0.9,0.2-1.1,1.5-1.2V122h-6.4v0.3
			c1.3,0.1,1.5,0.3,1.5,1.2v12.1c0,0.9-0.2,1.1-1.5,1.2v0.2h6.4v-0.2c-1.3-0.1-1.5-0.3-1.5-1.2v-5.9h6.6v5.9c0,0.9-0.2,1.1-1.5,1.2
			v0.2h6.4v-0.2c-1.3-0.1-1.5-0.3-1.5-1.2v-12.1c0-0.9,0.2-1.1,1.5-1.2V122h-6.4V122.3z"/>
		<path d="M368.6,135.5l-5.2-13.5h-2.7l-4.3,11.3c-0.9,2.1-1.3,3.1-2.1,3.4v0.2h4.5v-0.2c-0.1,0-0.1,0-0.2,0c-0.7-0.1-1.2-0.6-1.5-1
			c-0.3-0.5-0.3-1.2,0-2.1l0.4-1.1l0.5-1.3h5.4l1.5,4.3c0.4,1,0.2,1.2-1.6,1.3v0.2h6.6v-0.2C369.1,136.6,368.9,136.4,368.6,135.5
			 M359.3,127.9L359.3,127.9l1.5-3.8c0,0,0-0.1,0-0.1v0l0.1-0.2l2.2,6.2l0.3,0.7h-5.2l0.3-0.7L359.3,127.9z"/>
		<path d="M300.7,135.5l-5.2-13.5h-2.7l-4.3,11.3c-0.9,2.1-1.3,3.1-2.1,3.4v0.2h4.5v-0.2c-0.1,0-0.1,0-0.2,0c-0.7-0.1-1.2-0.6-1.5-1
			c-0.3-0.5-0.3-1.2,0-2.1l0.4-1.1l0.5-1.3h5.4l1.5,4.3c0.4,1,0.2,1.2-1.6,1.3v0.2h6.6v-0.2C301.2,136.6,301,136.4,300.7,135.5
			 M291.3,127.9L291.3,127.9l1.5-3.8c0,0,0-0.1,0-0.1v0l0.1-0.2l2.2,6.2l0.3,0.7h-5.2l0.3-0.7L291.3,127.9z"/>
		<path d="M274.8,134.1h-0.2c-0.3-1-0.6-1.8-1.2-3.1l-3.8-9.1h-4.7v0.3c1.2,0.1,1.5,0.3,1.5,1.5v9.1c0,2-0.3,3.1-1.5,3.9v0.2h4.3
			v-0.2c-1.8-0.6-2.5-1.8-2.5-4.3V127c0,0,0-0.1,0-0.1v-3h0.2l5.2,12.3h2.3l5.2-12.3h0.1v3.3v0.1v8.4c0,0.9-0.2,1.1-1.5,1.2v0.2h6.4
			v-0.2c-1.3-0.1-1.5-0.3-1.5-1.2v-11.8c0-1.2,0.2-1.4,1.5-1.5V122h-4.6L274.8,134.1z"/>
	</g>
</g>
</svg>
)