import { FilterSelectOption } from "./types";

const bedBathCarOptions: FilterSelectOption[] = [
    { label: "Any", value: '0' },
    { label: "1+", value: '1' },
    { label: "2+", value: '2' },
    { label: "3+", value: '3' },
    { label: "4+", value: '4' },
    { label: "5+", value: '5' },
    { label: "6+", value: '6' }
]

const rentPriceMin: FilterSelectOption[] = [
    { label: "$700", value: '700' },
    { label: "$800", value: '800' },
    { label: "$900", value: '900' },
    { label: "$1,000", value: '1000' },
    { label: "$1,200", value: '1200' },
    { label: "$1,400", value: '1400' },
    { label: "$1,600", value: '1600' },
    { label: "$1,800", value: '1800' },
    { label: "$2,000", value: '2000' },
    { label: "$2,500", value: '2500' },
    { label: "$3,000", value: '3000' },
    { label: "$3,500", value: '3500' },
]

const rentPriceMax: FilterSelectOption[] = [
    { label: "$800", value: '800' },
    { label: "$900", value: '900' },
    { label: "$1,000", value: '1000' },
    { label: "$1,200", value: '1200' },
    { label: "$1,400", value: '1400' },
    { label: "$1,600", value: '1600' },
    { label: "$1,800", value: '1800' },
    { label: "$2,000", value: '2000' },
    { label: "$2,500", value: '2500' },
    { label: "$3,000", value: '3000' },
    { label: "$3,500", value: '3500' },
    { label: "$4,000", value: '4000' },
    { label: "$4,500", value: '4500' },
    { label: "$4,500+", value: '20000' },
]

const priceMin = [
    { label: "$750,000", value: '750000' },
    { label: "$1,000,000", value: '1000000' },
    { label: "$1,250,000", value: '1250000' },
    { label: "$1,500,000", value: '1400000' },
    { label: "$1,750,000", value: '1750000' },
    { label: "$2,000,000", value: '2000000' },
    { label: "$2,250,000", value: '2250000' },
    { label: "$2,500,000", value: '2500000' },
    { label: "$2,750,000", value: '2750000' },
    { label: "$3,000,000", value: '3000000' },
    { label: "$3,500,000", value: '3500000' },
    { label: "$4,000,000", value: '4000000' },
    { label: "$4,500,000", value: '4500000' },
    { label: "$5,000,000", value: '5000000' },
]

const priceMax = [
    { label: "$1,000,000", value: '1000000'},
    { label: "$1,250,000", value: '1250000'},
    { label: "$1,500,000", value: '1400000'},
    { label: "$1,750,000", value: '1750000'},
    { label: "$2,000,000", value: '2000000'},
    { label: "$2,250,000", value: '2250000'},
    { label: "$2,500,000", value: '2500000'},
    { label: "$2,750,000", value: '2750000'},
    { label: "$3,000,000", value: '3000000'},
    { label: "$3,500,000", value: '3500000'},
    { label: "$4,000,000", value: '4000000'},
    { label: "$4,500,000", value: '4500000'},
    { label: "$5,000,000", value: '5000000'},
    { label: "$6,000,000", value: '6000000'},
    { label: "$7,000,000", value: '7000000'},
    { label: "$8,000,000", value: '8000000'},
    { label: "$9,000,000", value: '9000000'},
    { label: "$10,000,000+", value: '50000000'},
]

export {
    bedBathCarOptions,
    priceMin,
    priceMax,
    rentPriceMin,
    rentPriceMax,
}
