import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { hierarchicalMenu, Link } from '../../plugins/gatsby-plugin-atollon'
import { FullLogo, ChevronDown } from './icons'
import FormComponent from './form'
import {footerEmailClickEv, footerPhoneClickEv, formSubmissionEv} from "../utils/analytics";

const Footer = () => {
  const [toggle, setToggle] = React.useState(false)
  const [scrolled, setScrolled] = React.useState(false)


  const languageKey = 'au'

  const staticData = useStaticQuery(graphql`
    query DesktopFooterData {
      au: allDatoCmsHeaderMenuItem {
        nodes {
          id
          treeParent {
            id
          }
          title
          position
          link {
            ...LinkWithoutTitleProps
          }
          hideForLocale
        }
      }
      newsletterForm: datoCmsForm(title: {eq: "Subscribe"}) {
        ...FormProps
      }
    }
  `)
  const menuForLang = staticData[languageKey].nodes
  const menu = hierarchicalMenu(menuForLang)

  let isScrolling
  const onScroll = () => {
    setScrolled(window.scrollY > 100)
  }

  const scrollDetect = () => {
    window.clearTimeout(isScrolling)
    isScrolling = setTimeout(onScroll, 0)
  }

  React.useEffect(() => {
    window.addEventListener('scroll', scrollDetect, false)
    return () => {
      window.removeEventListener('scroll', scrollDetect, false)
    }
  }, [])

  let itemsArray = []
  for (let i = 0; i < menu.childMenu.length; i++) {
    itemsArray.push(false)
  }

  const [expandedItems, setExpandedItems] = React.useState(itemsArray)

  const handleItemClick = (index) => {
    const newExpandedItems = [...expandedItems]

    for (let i = 0; i < newExpandedItems.length; i++) {
      if (i !== index) newExpandedItems[i] = false
    }
    newExpandedItems[index] = !newExpandedItems[index]
    setExpandedItems(newExpandedItems)
    setToggle(true)
  }

  return (
    <footer className={`footer${scrolled ? ' footer--scrolled' : ''}`}>
      <div className='footer__top'>
        <div className='footer__inner'>
          <nav className='footer__nav'>
            { menu.childMenu &&
              <ul className='footer__nav-menu'>
                { menu.childMenu.map((item, index) => (
                  <li className={ `${ expandedItems[index] ? ' active' : '' }` } key={ index }>
                    <button onClick={ () => handleItemClick(index) } { ...item.link }>{ item.title } <ChevronDown color='#ffffff'/></button>
                    { item.childMenu?.length > 0 &&
                      <ul>
                        { item.childMenu.map((sub, i) => (
                          <li key={ i }>
                            <Link { ...sub.link }>{ sub.title }</Link>
                          </li>
                        )) }
                      </ul>
                    }
                  </li>
                )) }
              </ul>
            }
          </nav>
        </div>
      </div>
      <div className='footer__bottom'>
        <div className='footer__inner'>
          <div className={ `footer__subscribe` }>
            <h4>Sign up for latest property results</h4>
            <FormComponent onPreSubmit={(e) => {
              formSubmissionEv('Subscribe')
              fetch(`/api/newsletter`, {
                method: "POST",
                body: JSON.stringify({
                  firstName: e['first-name'],
                  lastName: e['last-name'],
                  phone: e.phone,
                  email: e.email
                })
              })
              return e;
            }} form={ staticData.newsletterForm }/>
            <ul>
              <li><Link onClick={footerPhoneClickEv} to='tel:0398229999'>03 9822 9999</Link></li>
              <li><Link onClick={footerEmailClickEv} to='mailto:contactus@marshallwhite.com.au'>contactus@marshallwhite.com.au</Link></li>
            </ul>
          </div>
          <div className='footer__logo'>
            <Link to='/'>
              <FullLogo color='#FFF'/>
            </Link>
            <ul>
              <li><Link to='/terms-and-conditions/'>Terms &amp; Conditions</Link></li>
              <li><Link to='/terms-and-conditions/'>Privacy Policy</Link></li>
              <li>© {new Date().getFullYear() } Marshall White</li>
              <li>Design by <Link to='https://atollon.com.au/' target='_blank' rel='noopener noreferrer'>Atollon</Link></li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
