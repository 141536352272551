import {kebab} from "../../plugins/gatsby-plugin-atollon/src/node/utils/case-conversion";

const universalClickEvent = (text: string, data?: { [key: string]: string | number }): void => {
    if (typeof window !== 'undefined') {
        //if (process.env.NODE_ENV === 'development') {
            //alert(`Event Triggered: ${text}`);
        //}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        window.dataLayer = window.dataLayer || [];
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        window.dataLayer.push({
            event: text,
            ...data,
        });
    }
};

const trackPageView = (overrideUrl?: string): void => {
    if (typeof window !== 'undefined') {
        const pageCategory = window.location.href.split('/')[2];
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        window.dataLayer = window.dataLayer || [];
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        window.dataLayer.push({
            event: 'virtualPageView',
            url: overrideUrl || window.location.href,
            title: document.title,
            category: pageCategory || 'Homepage',
        });
    }
};

const phoneClickAgentPageEv = (): void => {
    universalClickEvent('phone-click-agent-page');
};

const phoneClickAgentCardEv = (): void => {
    universalClickEvent('phone-click-agent-card');
};

const emailClickAgentPageEv = (): void => {
    universalClickEvent('email-click-agent-page');
};

const emailClickAgentCardEv = (): void => {
    universalClickEvent('email-click-agent-card');
};

type FormType = 'Property' | 'Agent Contact' | 'Contact' | 'Request Appraisal' | 'Finance' | 'Concierge' | 'Property Management' | 'Projects' | 'Subscribe' | 'Off Market' | 'Suburb Insights' | 'Sales' | 'Lease'

const formSubmissionEv = (formType: FormType): void => {
    universalClickEvent(`form-submission-${kebab(formType)}`);
};

const footerPhoneClickEv = () => {
    universalClickEvent('phone-click-footer');
}

const footerEmailClickEv = () => {
    universalClickEvent('email-click-footer');
}

const menuPhoneClickEv = () => {
    universalClickEvent('phone-click-menu');
}

const menuEmailClickEv = () => {
    universalClickEvent('email-click-menu');
}


export {
    trackPageView,

    phoneClickAgentPageEv,
    emailClickAgentPageEv,

    emailClickAgentCardEv,
    phoneClickAgentCardEv,

    formSubmissionEv,

    footerEmailClickEv,
    footerPhoneClickEv,

    menuEmailClickEv,
    menuPhoneClickEv,

};
