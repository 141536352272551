import React from 'react'
import {AtollonProvider} from "../../plugins/gatsby-plugin-atollon";
import layoutQuery from "../hooks/use-layout-query";
import '../assets/scss/main.scss'

import Header from '../components/header'
import Footer from '../components/footer'

const Layout = (props) => {

    const globalData = layoutQuery()

    return (
        <AtollonProvider {...props} globalData={globalData}>
            <Header pageContext={props.pageContext} />
            <main>
                { props.children }
            </main>
            <Footer />
            <script
                dangerouslySetInnerHTML={{ __html: `
                window.__lxt_cache = window.__lxt_cache || [];
                function lxt() {
                    window.__lxt_cache.push(arguments);
                };
                window.lxt_attributes = {
                    agent_called: '5dfe60e49d9f4c29a5afe94bbc086c1a',
                    agent_emailed: 'd37241201e4647e78cd9e6d3ce763259',
                    agent_viewed: 'b75dd65df9c3415588193706312ebb2a',
                    booked_inspection: '812765216cec4a60ad8bc22015f437ab',
                    buy_bathrooms_viewed: 'd83a4fa3b1244901940de650a41bef78',
                    buy_bedrooms_viewed: 'ba5c4e95492d43f18373dda51e67e74b',
                    buy_driveway_viewed: '916448d764044ac9b7c5c903356752aa',
                    buy_property_viewed: '3024ff1f2328485e8a22776759902a33',
                    buy_suburb_viewed: 'f2c74465fdb145078b52ca6832747389',
                    custom_id_1: 'bc021cda1f474be4858ea06a316f3cb9',
                    date_of_last_visit: '9c62e83254214f3e81f09ef02f16edb3',
                    dates_visited: '7356081b640846e0a18ccc5abaa6aa84',
                    email: '1bc11a0ac53b4529946658830938e367',
                    email_sha: '71cda5633f9d43cc9127043ac7745632',
                    page_viewed: '8674541adf4a46a2840f3f4d57db5b26',
                    rental_bathrooms_viewed: '01f3c0e09ec7482e9d7e1f6182aecf9a',
                    rental_bedrooms_viewed: '5993c548e62649c4ac1335f5c55d8980',
                    rental_driveway_viewed: 'd876244e40a742b78281a8a3daa1be4d',
                    rental_property_viewed: 'c26cbeee50fb4485a5aecbf577522437',
                    rental_suburb_viewed: '2c6fea5b394744479922e190ccd3533b',
                    requested_appraisal_address: '315a97a963c643f0bb5b670dfe2e1fbc',
                    requested_appraisal_agent_name: '464de7b3befc491d9bac919b2aefbfc2',
                    requested_appraisal_suburb: 'b953fff28120444390446df492e23842',
                    soi_downloaded: '6b01a6be5cdc4fa981736fb1ef584224',
                    subpage_viewed: 'bdfe845285724557b5b1a9978043479e',
                    type_viewed: '0bcd199952eb4de8990003cc095725b9',
                    utm_campaign: '687e46e4dab840559f08ccef302a17df',
                    utm_content: 'de0e3faae30041e7ad857b891d79f7b3',
                    utm_medium: '1dedae380e664608ade3cbbfdf98101e',
                    utm_source: '6c3013da52dc42608e16e10b0fd05eb1',
                    utm_term: '96e59ceb0ce1497ea7cbf6f59c100f9c'
                };
                lxt('initiate', '261c99439eec4f0cadb59442eceedd49', window.lxt_attributes);
                lxt('track', 'date_of_last_visit', new Date().toISOString().substring(0,10)+"T00:00:00.000Z");
                lxt('track', 'dates_visited',new Date().toISOString().substring(0,10)+"T00:00:00.000Z");
            `}}
            />
            <script async src="https://tag.lexer.io/lxt.js" />
        </AtollonProvider>
    )
}

export default Layout
