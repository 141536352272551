import { useStaticQuery, graphql } from "gatsby"

const useOfficeQuery = () => {
  const { allDatoCmsOffice } = useStaticQuery(graphql`
  query Offices {
    allDatoCmsOffice(
      filter: {hideForLocale: {ne: true}}
      sort: {position: ASC}
    ) {
      nodes {
        phone
        title
        permalink
        id
        remoteId
        addressComponents {
          unit
          streetNumber
          street
          city
          postCode
        }
        email
        hideForLocale
        bannerImage {
          ...InternalAssetProps
        }
        thumbnail {
          ...InternalAssetProps
        }
      }
    }
  }
`)

  return allDatoCmsOffice.nodes
}

export default useOfficeQuery