import React from 'react'
import { Logo, Castle } from './icons'
import {graphql, useStaticQuery} from "gatsby";
import { hierarchicalMenu, Link } from "../../plugins/gatsby-plugin-atollon"
import { Phone, Email, Facebook, LinkedIn, Instagram, Pinterest, ChevronDown } from './icons'
import {menuEmailClickEv, menuPhoneClickEv} from "../utils/analytics";

const Header = ({ pageContext }) => {

  const [offCanvas, setOffCanvas] = React.useState(false)
  const [scrolled, setScrolled] = React.useState(false)
  const [reachedFooter, setReachedFooter] = React.useState(false)
  const [sticky, setSticky] = React.useState(false)
  const [offCanvasMenu, setOffCanvasMenu] = React.useState({
    subActive: false,
    menu: [],
    initial: false,
  })
  const [dropdownMenu, setDropdownMenu] = React.useState({
    dropdownActive: false,
    menu: [],
    initial: false,
  })
  const closeMenu = () => {
    setOffCanvasMenu({
      subActive: false,
      menu: [],
      initial: false,
    })
    setDropdownMenu({
      dropdownActive: false,
      menu: [],
      initial: false,
    })
    setOffCanvas(false)
  }


  const languageKey = 'au'

  const staticData = useStaticQuery(graphql`
    query DesktopHeaderData {
      au: allDatoCmsHeaderMenuItem {
        nodes {
          id
          treeParent {
            id
          }
          title
          position
          link {
            ...LinkWithoutTitleProps
          }
          hideForLocale
        }
      }
    }
  `);
  const menuForLang = staticData[languageKey].nodes
  const menu = hierarchicalMenu(menuForLang)

  let isScrolling
  let prevScroll = 0

  const onScroll = (e) => {

    let scr, hhScrolled

    const curScroll = window.scrollY || document.scrollTop
    const bannerHeight = document?.querySelector('.landing')?.offsetHeight
    const stickyHeader = document?.querySelector('.sticky-header')
    const windowHeight = window.innerHeight;
    const documentHeight = document.documentElement.scrollHeight;
    scr = curScroll > 1
    const direction = curScroll > prevScroll ? 'down' : 'up'

    // Make sure they scroll more than 5px
    if (Math.abs(prevScroll - curScroll) <= 5) return

    if (documentHeight - curScroll - 150 <= windowHeight) {
      setReachedFooter(true);
    } else {
      setReachedFooter(false);
    }

    if (stickyHeader) {
      if (direction === 'down') {
        setSticky(true)
        stickyHeader.classList.add('active')
      } else {
        setSticky(false)
        stickyHeader.classList.remove('active')
      }
    } else {
      setSticky(false)
    }

    prevScroll = curScroll

    requestAnimationFrame(() => {
      setScrolled(scr)
    })
  }

  const scrollDetect = () => {
    window.clearTimeout(isScrolling)
    isScrolling = setTimeout(onScroll, 0)
  }

  const offCanvasMenuClick = (key) => {
    let { subActive, menu, initial } = offCanvasMenu
    subActive = false
    initial = false

    if (offCanvasMenu.menu[key]) {
      setOffCanvasMenu({
        subActive: false,
        menu: [],
        initial: false,
      })
      return
    }

    if (menu[key]) {
      menu[key] = true
    } else {
      for (let i = 0; i < menu.length; i++) {
        menu[i] = false
      }
      menu[key] = true
    }

    subActive = true

    setOffCanvasMenu({ initial, menu, subActive })
  }

  const dropdownToggle = (key) => {
    let { dropdownActive, menu, initial } = dropdownMenu
    dropdownActive = false
    initial = false

    if (dropdownMenu.menu[key]) {
      setDropdownMenu({
        dropdownActive: false,
        menu: [],
        initial: false,
      })
      return
    }

    if (menu[key]) {
      menu[key] = true
    } else {
      for (let i = 0; i < menu.length; i++) {
        menu[i] = false
      }
      menu[key] = true
    }

    dropdownActive = true

    setDropdownMenu({ initial, menu, dropdownActive })
  }

  React.useEffect(() => {
    window.addEventListener('scroll', scrollDetect, false)
    return () => {
      window.removeEventListener('scroll', scrollDetect, false)
    }
  }, [])

  let props = {
    onClick: () => closeMenu(),
    activeClassName: 'active',
  }

  let headerClass = 'header'
  if ( scrolled ) headerClass += ' header--scrolled'
  if ( reachedFooter ) headerClass += ' header--footer'
  if ( offCanvas ) headerClass += ' header--off-canvas'
  if ( sticky ) headerClass += ' header--sticky'
  if ( dropdownMenu.dropdownActive ) headerClass += ' header--dropdown-active'
  if ( pageContext.header ) headerClass += ` header--${pageContext.header}`

  return (
    <>
      <header className={headerClass}>
        <div className='header__inner'>
          <div className='header__logo'>
            <Link to='/' {...props}>
              <div className='header__logo--wordmark'>
                <Logo />
              </div>
              <div className='header__logo--castle'>
                <Castle />
              </div>
            </Link>
          </div>
          <nav className='header__nav'>
            { menu.childMenu &&
              <ul className={dropdownMenu.dropdownActive ? 'dropdown-active' : ''}>
                { menu.childMenu.map((item, index) => (
                  <li key={index} onMouseEnter={() => dropdownToggle(index)} onMouseLeave={() => closeMenu()} className={dropdownMenu.menu[index] ? 'active' : ''}>
                    <span><Link {...item.link}>{item.title}</Link></span>
                    { item.childMenu?.length > 0 &&
                      <div className='header__dropdown'>
                        <ul>
                          { item.childMenu.map((sub, i) => (
                            <li key={i}>
                              <Link {...sub.link} {...props}>{sub.title}</Link>
                            </li>
                          )) }
                        </ul>
                      </div>
                    }
                  </li>
                )) }
              </ul>
            }
          </nav>
          <button onClick={() => setOffCanvas(!offCanvas)} className={offCanvas ? 'header__hamburger active' : 'header__hamburger' }>
            <span className='lines'></span>
          </button>
        </div>
      </header>
      <div className={`off-canvas ${offCanvas ? 'active' : ''} ${offCanvasMenu.subActive ? 'dropdown-active' : ''}`}>
        <div className='off-canvas__inner'>
          <nav className='off-canvas__nav'>
            { menu.childMenu &&
              <ul className={offCanvasMenu.subActive ? 'sub-active' : ''}>
                { menu.childMenu.map((item, index) => (
                  <li key={index} className={offCanvasMenu.menu[index] ? 'active' : ''}>
                    <button onClick={() => offCanvasMenuClick(index)}>{item.title} <ChevronDown color='#ffffff'/></button>
                    { item.childMenu?.length > 0 &&
                      <div>
                        <ul>
                          { item.childMenu.map((sub, i) => (
                            <li key={i}>
                              <Link {...sub.link} {...props}>{sub.title}</Link>
                            </li>
                          )) }
                        </ul>
                      </div>
                    }
                  </li>
                )) }
              </ul>
            }
          </nav>
          <ul className='off-canvas__social'>
            <li><span><Facebook color='#fff' /></span><Link to='https://www.facebook.com/MarshallWhiteRE/'>Facebook</Link></li>
            <li><span><Instagram color='#fff' /></span><Link to='https://www.instagram.com/marshallwhiterealestate/?hl=en'>Instagram</Link></li>
            <li><span><LinkedIn color='#fff' /></span><Link to='https://au.linkedin.com/company/marshall-white-&-co'>LinkedIn</Link></li>
            <li><span><Pinterest color='#fff' /></span><Link to='https://www.pinterest.com.au/marshallwhitere/'>Pinterest</Link></li>
            <li>&nbsp;</li>
            <li><span><Email color='#fff' /></span><Link onClick={menuEmailClickEv} to='mailto:contactus@marshallwhite.com.au'>contactus@marshallwhite.com.au</Link></li>
            <li><span><Phone color='#fff' /></span><Link onClick={menuPhoneClickEv} to='tel:+61398229999'>03 9822 9999</Link></li>
            <li>&nbsp;</li>
            <li><Link to='/contact/' className='btn btn--border'>Contact Us</Link></li>
          </ul>
        </div>
      </div>
      <button className={`off-canvas__exit${offCanvas ? ' active' : ''}`} type='button' {...props} />
    </>
  )
}

export default Header
